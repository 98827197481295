import React, { useState, useEffect, useRef } from 'react'
import moment from 'moment';
import AutosuggestComponent from '../../../../Utility/Components/AutosuggestComponent'
import ErrorBoundary from '../../../../Utility/Components/ErrorBoundary';
import ModalGlobal from '../../../../Utility/Components/ModalGlobal';
import { connect } from 'react-redux';
import { loaderStateTrue, loaderStateFalse, userSearchData, selectChooseBranch, priceDistributionRequestData, priceDistributionResponseData } from '../../../../Actions/AllAction';
import { setToken, setUserCredentials } from '../../../../GlobalModules/Login/Actions/LoginAction';
import { paymentPriceDistribution, emailCheckApi, signupCustomer } from '../Controller/HomeController'
import Utility from '../../../../Utility/Utility';
import ValidateUtility from '../../../../Utility/ValidateUtility';
import PhoneNumberWithCountryCode from '../../../../Utility/Components/PhoneNumberWithCountryCode';
import CustomInput from '../../../../Utility/Components/CustomInput';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { Routes, Route, Switch, useHistory } from "react-router-dom";
import PaymentModeModalContent from './PaymentModeModalContent';
import Accordion from 'react-bootstrap/Accordion';
import ConfirmationAlert from '../../../../Utility/Components/ConfirmationAlert'
import EventEmitter from '../../../../Utility/Components/EventEmitter';
import { Modal, Container, Alert } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import LoginUtility from '../../../../GlobalModules/Login/Utility/LoginUtility'
import * as LoginController from '../../../../GlobalModules/Login/Controller/LoginController';
import parsePhoneNumber from 'libphonenumber-js'
import { customerWorkStatus } from '../../../../Utility/Controller/UtilityController'

function PaymentDetailsModalContent(props) {
    const history = useHistory();
    const paymentDetailsRef = useRef(true);
    const [formatedArr, setFormatedArr] = useState([]);
    const [branchWiseFormatedArr, setBranchWiseFormatedArr] = useState([]);
    const [paymentDetailsSaveData, setPaymentDetailsSaveData] = useState({});
    const [branchId, setBranchId] = useState('');
    const [totalPayableAmount, setTotalPayableAmount] = useState("");
    const [minimumPayablePercentage, setMinimumPayablePercentage] = useState("");
    const [minimumPayableAmount, setMinimumPayableAmount] = useState("");
    const [bookingId, setBookingId] = useState("");
    const [bookingIndex, setBookingIndex] = useState("");
    const [paymentModalFlag, setPaymentModalFlag] = useState(false);
    const [existingCustomerFlag, setExistingCustomerFlag] = useState(true);
    const [selectedCountryCode, setSelectedCountryCode] = useState("in");
    const [formData, setFormData] = useState({
        "name": "", "last_name": "", "phone_number": "", "email": [{ 'email': "" }], "customer_gstin_no": "",
        "company_name": "", "company_address": ""
    });
    const [formDataError, setFormDataError] = useState({ "name": "", "last_name": "", "phone_number": "", "email": [{ 'email': "" }], "customer_gstin_no": "", "company_name": "", "company_address": "" });
    const [customerList, setCustomerList] = useState([]);
    const [selectedCustomer, setSelectedCustomer] = useState({});
    const [dateWiseRateDetailsModalFlag, setDateWiseRateDetailsModalFlag] = useState(false);
    const [dateWiseRateDetailsData, setDateWiseRateDetailsData] = useState({});
    const [currencyName, setCurrencyName] = useState("INR");
    const [customerId, setCustomerId] = useState("");
    const [paymentDetailsDataSet, setPaymentDetailsDataSet] = useState({});
    const [hotelRoomBookDataSetCopy, setHotelRoomBookDataSetCopy] = useState([]);
    const [roomBookCreateModalFlag, setRoomBookCreateModalFlag] = useState(false)
    const [roomBookEditState, setRoomBookEditState] = useState({ 'location': "", "start_date": "", 'end_date': "", 'booking_id': "", 'child': "", 'adult': '', 'branch_id': "" })
    const [descriptionModalFlag, setDescriptionModalFlag] = useState(false)
    const [dateKeyValue, setDateKeyValue] = useState("")
    const [descriptionText, setDescriptionText] = useState("")
    const [descriptionBoxStatus, setDescriptionBoxStatus] = useState(true)
    const [isNewBooking, setIsNewBooking] = useState(true)
    const [payableServiceList, setPayableServiceList] = useState([]);
    const emailInputRef = useRef(null);
    const [bookingDetailsDatasetWithoutPayment, setBookingDetailsDatasetWithoutPayment] = useState(null);
    const [bookingCancellationModalFlag, setBookingCancellationModalFlag] = useState(false);
    const [forcePasswordChange, setForcePasswordChange] = useState(false);
    const [password, setPassword] = useState("");
    const [retypePassword, setRetypePassword] = useState("");
    const [veryfiCode, setveryfiCode] = useState("");
    const [veryfiFlag, setveryfiFlag] = useState(false);
    const [codeError, setcodeError] = useState("");
    const [updated_passwordError, setupdated_passwordError] = useState("");
    const [updated_comfirm_passwordError, setupdated_comfirm_passwordError] = useState("");
    const [createNewPassword, setCreateNewPassword] = useState("");
    //change pass
    const [changePasswordFlag, setChangePasswordFlag] = useState(false);
    const [zoominzoomoutflag, setZoominzoomoutflag] = useState("");
    const [emailPassword, setEmailPassword] = useState("");
    const [state, setState] = useState({
        changePasswordLock: false,
        changePasstype: "password",
        changeConpasstype: "password",
        changeConpasswordLock: false
    })
    const [phoneNumberCountryCode, setPhoneNumberCountryCode] = useState("in");
    const [phoneNumberDialCode, setPhoneNumberDialCode] = useState("91");
    const [alertContainer, setAlertContainer] = useState(false);

    //initially check existing customer or new customer
    useEffect(() => {
        if (paymentDetailsRef.current) {
            paymentDetailsRef.current = false;
            if (props.customerSelection == true) {
                setExistingCustomerFlag(false);
                setBookingId(props.bookingEditId)
            }
        }
    }, [])


    //check upon callFrom props check customer existing or new customer
    useEffect(() => {
        if (props.callFrom == "edit_quotation") {
            if (props.customerIdProp != "0") {
                setExistingCustomerFlag(true)
            } else {
                setExistingCustomerFlag(false)
            }
        }
        if (props.callFrom == "edit_booking") {
            if (props.customerIdProp == "0") {
                setExistingCustomerFlag(false)
            }
        }
    }, [props.callFrom])

    //depends on payableNoteList props set additional service list
    useEffect(() => {
        if (props.payableNoteList.length > 0) {
            setPayableServiceList(props.payableNoteList)
        }
    }, [props.payableNoteList])


    //depends on Customer list set the selected customer
    useEffect(() => {
        if (props.callFrom == "edit_quotation" && customerList.length > 0) {
            setSelectedCustomer(props.selectedCustomer)
        }
    }, [customerList])


    useEffect(() => {
    }, [formData])

    useEffect(() => {
        if (props.callFrom == "edit_quotation") {
            if (props.hotelRoomBookDataSet.length > 0) {
                setRoomBookEditState({
                    'location': props.hotelRoomBookDataSet[0].location,
                    "start_date": props.hotelRoomBookDataSet[0].book_from,
                    'end_date': props.hotelRoomBookDataSet[0].book_to,
                    'booking_id': props.hotelRoomBookDataSet[0].booking_id,
                    'child': props.hotelRoomBookDataSet[0].child,
                    'adult': props.hotelRoomBookDataSet[0].adult,
                    'branch_id': props.hotelRoomBookDataSet[0].branch_id
                })
            }
        }
    }, [props.hotelRoomBookDataSet])

    const cancellationModalHide = () => {
        setBookingCancellationModalFlag(false);
    }

    const getCustomerListApiFn = () => {
        const promise = new Promise((resolve, reject) => {
            const { loaderStateTrue, loaderStateFalse } = props;
            loaderStateTrue();
            let data = {};
            let filters = {};
            data['user_type'] = "customer";
            data['sorting'] = [{ "sort": "asc", "colId": "name" }];
            filters['filters'] = JSON.stringify(data)
            customerListApi(filters).then((response) => {
                let customerArray = []
                if (response.success) {
                    response.data.map((e) => {
                        let customerListObj = {};
                        var phoneNumber = e.contact_number
                        var plusRemovePhoneNumber = phoneNumber.replace(/\+/g, '')
                        customerListObj['label'] = e.first_name + " " + e.last_name + " " + `(${(e.contact_number)})`;
                        customerListObj['value'] = e.contact_number;
                        customerListObj['name'] = e.first_name;
                        customerListObj['last_name'] = e.last_name;
                        customerListObj['phone_number'] = e.contact_number;
                        customerListObj['email'] = e.email;
                        customerListObj['id'] = e.id;
                        customerListObj['customer_gstin_no'] = "";
                        customerListObj['company_name'] = "";
                        customerListObj['company_address'] = "";
                        customerArray.push(customerListObj)
                    })
                    if (customerArray.length > 0) {
                        setCustomerList(customerArray);
                    }

                }
                //setCustomerList(customerArray);
                loaderStateFalse();
            }).catch((error) => {
                console.error("************error*************", error)
                if (error) {
                }
                loaderStateFalse();
                if (error.message == "Network Error") {
                }
            });
        })
        return promise;
    }


    const bookedSuccessfullyFn = (index) => {
        setBookingId("");
        const updatedBranchData = [...branchWiseFormatedArr];
        updatedBranchData[index] = {
            ...updatedBranchData[index],
            is_booked_sucessfully: !updatedBranchData[index].is_booked_sucessfully,
        };
        setBranchWiseFormatedArr(updatedBranchData);
    }

    //confirm booking create function
    const paymentAndBookingFn = () => {
        if (props.isGuestFlag) {
            let valid = validConfirmBookingModalFn() //validate booking form before submitting
            if (!valid) {
                let pageBottom = document.querySelector('#page-bottom');
                pageBottom.scrollIntoView({ behavior: 'smooth' });
            }
            if (valid) {
                let updatedFormDataError = { ...formDataError };
                const { loaderStateTrue, loaderStateFalse } = props;
                loaderStateTrue();
                let data = { email: formData['email'][0].email, contact_number: formData.phone_number }
                emailCheckApi(data).then((response) => {
                    if (response.message == "Email is eligible") {
                        const timestamp = Date.now();
                        const lastFourDigits = timestamp.toString().slice(-4);
                        let createNewPassword = `TheStaying${lastFourDigits}1!2`;
                        setCreateNewPassword(createNewPassword)
                        setForcePasswordChange(false);
                        setveryfiFlag(false);
                    } else {
                        setBookingCancellationModalFlag(true);
                    }
                    loaderStateFalse();
                }).catch((error) => {
                    if (!error.response.data.success) {
                        updatedFormDataError['email'][0].email = error.response.data.message
                        setFormDataError(updatedFormDataError);
                        if (error.response.data.message == "A customer already exists with this email") {
                            history.push(`/${localStorage.getItem('i18nextLng')}/login/customerexits`)
                        }
                    }
                    loaderStateFalse();
                    if (error.message == "Network Error") {

                    }
                });
            }
        } else {
            setBookingCancellationModalFlag(true);
        }
    }

    //set new Password to update password state
    useEffect(() => {
        if (createNewPassword != "") {
            updatePassword(createNewPassword);
        }
    }, [createNewPassword])

    //when customer open price distribution page , then visitor api hits through this function
    useEffect(() => {
        if (props.isLoggedIn) {
            let dataset = {
                [props.selected_branch?.branch_id]:
                {
                    type: "booking proceed"
                }
            }
            customerWorkStatus(dataset);
        }

    }, [props.isLoggedIn])


    //verification code submit functionality
    const updatePasswordFn = () => {
        if (createNewPassword != "") {
            updatePassword(createNewPassword);
        }
    }

    //booking confirmation modal confirm button click functionality
    const confirmbookingConfirmButton = (type = "") => {
        if (props.selected_branch.branch_wise_booking_rule && Number(props.selected_branch.branch_wise_booking_rule.booking_advance_percent) > 0) {
            let dataSet = FormattingSendQuoatationDataFn("booking");
            setBookingDetailsDatasetWithoutPayment(dataSet);
            setBookingCancellationModalFlag(false);
            if (typeof type === 'object') {
                setPaymentModalFlag(true)
            }
        } else {
            saveConfirmBookingData();
        }
    }


    //booking create post api request function without payment
    const saveConfirmBookingData = () => {
        const { loaderStateTrue, loaderStateFalse } = props;
        let bookingDataset = FormattingSendQuoatationDataFn("booking");
        bookingDataset[0]['payment_arr'] = []
        let combinedData = bookingDataset
        loaderStateTrue();
        paymentPriceDistribution(combinedData).then((response) => {
            if (response.length > 0) {
                if (response[0].success) {
                    closePayModalSuccessFn();
                    setBookingCancellationModalFlag(false);
                    Utility.toastNotifications(response[0].message, "Success", "success");
                } else {
                    Utility.toastNotifications(response[0].message, "Error", "error");
                }
            }
            loaderStateFalse();
        }).catch((error) => {
            console.error("************error*************", error)
            if (error) {
            }
            loaderStateFalse();
            if (error.message == "Network Error") {

            }
        });
    }

    //close payment modal fn
    const closePaymentModalFn = (data) => {
        setPaymentModalFlag(data);
    }

    //close payment modal callback fn  
    const closePaymentModalSuccessFn = (data) => {
        setPaymentModalFlag(data);
        props.closePaymentDetailsModalFnCall(false)
    }


    const sendBookingId = (data) => {
        setBookingId(data)
    }

    //booking dataset formatting fn
    const formattingConfirmBookingDatasetFn = () => {
        const { hotelRoomTypesSearchData, childrenAges, numAdults, roomBookFormData } = props;
        let roomBookArr = [];
        const currentDate = new Date();
        let hotelRoomTypesSearchDataTempArrCopy = { ...hotelRoomTypesSearchData };
        let _hash = {};
        const dateWiseRoomDetails = {};
        if (Object.keys(hotelRoomTypesSearchDataTempArrCopy).length > 0) {
            hotelRoomTypesSearchDataTempArrCopy.branch_details.forEach((branchDetails) => {
                branchDetails.roomtype_details.forEach((roomtypeDetails) => {
                    if (roomtypeDetails.checked) {
                        if (props.customerSelection) {
                            _hash["booking_id"] = bookingId;
                        }
                        _hash["org_id"] = hotelRoomTypesSearchDataTempArrCopy.org_id;
                        _hash["branch_id"] = branchDetails.branch_id;
                        _hash["book_from"] = moment(roomBookFormData.start_date.toString()).format('YYYY-MM-DD');
                        _hash["book_to"] = moment(roomBookFormData.end_date.toString()).format('YYYY-MM-DD');
                        _hash["adult"] = numAdults;
                        let agesArr = []
                        if (childrenAges.length > 0) {
                            childrenAges.map((val, idx) => {
                                let agesObj = {}
                                agesObj['age'] = val.age.value
                                agesArr.push(agesObj)
                            })
                        }
                        _hash["child"] = JSON.stringify(agesArr);
                        _hash["paid_amount"] = 0;
                        _hash["payment_type"] = ""

                        if (Object.keys(props.guestDetailsData).length > 0) {
                            _hash["customer_id"] = 0;
                            _hash["customer_name"] = props.guestDetailsData.first_name + " " + props.guestDetailsData.last_name;
                            _hash["customer_phone"] = props.guestDetailsData.phone_number;
                            _hash["customer_email"] = props.guestDetailsData.customer_email;
                            _hash["customer_gstin_no"] = props.guestDetailsData.customer_gstin_no;
                            _hash["company_name"] = props.guestDetailsData.company_name;
                            _hash["company_address"] = props.guestDetailsData.company_address;
                        } else {
                            _hash["customer_id"] = props.customerSelection ? customerId : selectedCustomer.hasOwnProperty('id') ? selectedCustomer.id : 0;
                            _hash["customer_name"] = formData.name + " " + formData.last_name;
                            _hash["customer_phone"] = formData.phone_number;
                            _hash["customer_email"] = formData.email;
                        }

                        _hash["is_igst_applicable"] = paymentDetailsDataSet.hasOwnProperty('is_igst_enable') && paymentDetailsDataSet.is_igst_enable ? true : false;
                        const startDate = moment(roomBookFormData.start_date);
                        const endDate = moment(roomBookFormData.end_date);

                        let currentDate = startDate.clone();
                        while (currentDate.isBefore(endDate)) {
                            const dateString = currentDate.format('YYYY-MM-DD');
                            dateWiseRoomDetails[dateString] = dateWiseRoomDetails[dateString] ? dateWiseRoomDetails[dateString] : {}
                            if (!props.customerSelection) {
                                dateWiseRoomDetails[dateString][roomtypeDetails.id] = {
                                    "count": parseInt(roomtypeDetails.selectedRooms.value),
                                    "room_id": ""
                                };
                            } else {
                                let roomIdArray = roomtypeDetails.availableRoomChoose.length > 0 ? roomtypeDetails.availableRoomChoose.map((e) => e.value) : [];
                                let roomIdString = roomIdArray.join(',');
                                dateWiseRoomDetails[dateString][roomtypeDetails.id] = {
                                    "count": parseInt(roomtypeDetails.selectedRooms.value),
                                    "room_id": roomIdString
                                };
                            }
                            currentDate.add(1, 'days');
                        }
                    }
                });
            });
        }
        _hash["date_wise_room_details"] = dateWiseRoomDetails;
        roomBookArr.push(_hash);
        return roomBookArr;
    };

    const confirmBookingFnFinal = () => {
        let valid = validConfirmBookingModalFn()
        if (valid) {
            let dataSet = formattingConfirmBookingDatasetFn();
            if (props.customerSelection == false) {
                if (!existingCustomerFlag) {
                    gaustDataSave().then((response) => {
                        dataSet[0]["customer_id"] = response.data[0].id;
                        hotelRoomBookingsAfterCustomerCreation(dataSet)
                    })
                } else {
                    hotelRoomBookingsAfterCustomerCreation(dataSet)
                }
            } else {
                hotelRoomBookingsAfterCustomerCreation(dataSet)
            }
        }
    }

    const hotelRoomBookingsAfterCustomerCreation = (dataSet) => {
        const { loaderStateTrue, loaderStateFalse } = props;
        loaderStateTrue();
        hotelRoomBookings(dataSet).then((response) => {
            response.map((value) => {
                if (value.success) {
                    let bookingIdTemp = props.customerSelection ? bookingId : value.data.booking_id;
                    Utility.toastNotifications(value.message, "Success", "success");
                    props.closePaymentDetailsModalFnCall(false)
                    loaderStateFalse();
                } else {
                    Utility.toastNotifications(value.message, "Error", "error");
                    loaderStateFalse();
                }
            })
        }).catch((error) => {
            console.error("************error*************", error)
            if (error) {
            }
            loaderStateFalse();
            if (error.message == "Network Error") {

            }
        });
    }

    const handleChangeCustomerType = (e, type) => {
        const { checked } = e.target;
        if (type === "existing") {
            if (checked) {
                setExistingCustomerFlag(true);
            }
        }
        if (type === "new") {
            if (checked) {
                setExistingCustomerFlag(false);
            }
        }
    }

    //set the customer in customer details field
    useEffect(() => {
        if (props.callFrom != "edit_quotation") {
            if (!existingCustomerFlag) {
                setFormData({ "name": "", "last_name": "", "phone_number": "91", "email": [{ 'email': "" }], "customer_gstin_no": "", "company_name": "", "company_address": "" });
                setFormDataError({ "name": "", "last_name": "", "phone_number": "", "email": [{ 'email': "" }], "customer_gstin_no": "", "company_name": "", "company_address": "" });
                setSelectedCustomer({});
                setSelectedCountryCode("in")
                if (props.customerSelection == true) {
                    if (Object.keys(props.customerData).length > 0) {
                        setFormData({ "name": props.customerData.name, "last_name": props.customerData.last_name, "phone_number": props.customerData.phone_number, "email": [{ 'email': props.customerData.email }], "customer_gstin_no": props.customerData.customer_gstin_no, "company_name": props.customerData.company_name, "company_address": props.customerData.company_address })
                        setFormDataError({ "name": "", "last_name": "", "phone_number": "", "email": [{ 'email': "" }], "customer_gstin_no": "", "company_name": "", "company_address": "" });
                        setCustomerId(props.customerData.id)
                    }
                }
            } else {
                setFormData({ "name": "", "last_name": "", "phone_number": "91", "email": [{ 'email': "" }], "customer_gstin_no": "", "company_name": "", "company_address": "" });
                setFormDataError({ "name": "", "last_name": "", "phone_number": "", "email": [{ 'email': "" }], "customer_gstin_no": "", "company_name": "", "company_address": "" });
                setSelectedCustomer({});
                setSelectedCountryCode("in")
            }
        } else {

            if (!existingCustomerFlag) {
                setFormData(props.formData);
                setFormDataError({ "name": "", "last_name": "", "phone_number": "", "email": [{ 'email': "" }], "customer_gstin_no": "", "company_name": "", "company_address": "" });
                setSelectedCustomer({});
                setSelectedCountryCode("in")
            } else {
                setFormData(props.formData);
                setSelectedCustomer(props.selectedCustomer)
            }
        }
        if (props.callFrom == "edit_booking") {
            setFormData(props.customerData);
            setFormDataError({ "name": "", "last_name": "", "phone_number": "", "email": [{ 'email': "" }], "customer_gstin_no": "", "company_name": "", "company_address": "" });
            setSelectedCustomer({});
            setSelectedCountryCode("in")
        }
    }, [existingCustomerFlag])

    //customer details field on change function
    const handelChangeCustomerFn = (e, type, dialCodeObj = {}) => {
        let tempFormData = { ...formData };
        let tempFormDataError = { ...formDataError }

        if (type == "name") {
            var expr = /^[a-zA-Z ]*$/;
            if (!expr.test(e.target.value)) {
            } else {
                if (e.target.value == "") {
                    tempFormDataError['name'] = ""
                    tempFormData["name"] = ""
                } else {
                    tempFormData["name"] = e.target.value
                    tempFormDataError['name'] = ""
                }
            }
        }
        if (type == "last_name") {
            var expr = /^[a-zA-Z ]*$/;
            if (!expr.test(e.target.value)) {
            } else {
                if (e.target.value == "") {
                    tempFormDataError['last_name'] = ""
                    tempFormData["last_name"] = ""
                } else {
                    tempFormData["last_name"] = e.target.value
                    tempFormDataError['last_name'] = ""
                }
            }
        }

        if (type == "customer_gstin_no") {
            if (e.target.value == "") {
                tempFormDataError['customer_gstin_no'] = ""
                tempFormData["customer_gstin_no"] = ""
            } else {
                let gstnValidate = ValidateUtility.validate_gstn_Number(e.target.value);
                if (gstnValidate) {
                    tempFormData["customer_gstin_no"] = e.target.value
                    tempFormDataError['customer_gstin_no'] = ""
                } else {
                    tempFormData["customer_gstin_no"] = e.target.value
                    tempFormDataError['customer_gstin_no'] = "Please enter a correct GSTIN No"
                }
            }
        }

        if (type == "company_name") {
            var expr = /^[a-zA-Z ]*$/;
            if (!expr.test(e.target.value)) {
            } else {
                if (e.target.value == "") {
                    tempFormDataError['company_name'] = ""
                    tempFormData["company_name"] = ""
                } else {
                    tempFormData["company_name"] = e.target.value
                    tempFormDataError['company_name'] = ""
                }
            }
        }

        if (type == "company_address") {
            if (e.target.value == "") {
                tempFormDataError['company_address'] = ""
                tempFormData["company_address"] = ""
            } else {
                tempFormData["company_address"] = e.target.value
                tempFormDataError['company_address'] = ""
            }
        }

        if (type == "email") {
            if (e.target.value == "") {
                tempFormDataError['email'] = ""
                tempFormData["email"] = ""
            } else {
                let emailValidate = ValidateUtility.validate_email(e.target.value);
                if (emailValidate) {
                    tempFormData["email"] = e.target.value
                    tempFormDataError['email'] = ""
                } else {
                    tempFormData["email"] = e.target.value
                    tempFormDataError['email'] = "Enter valid email"
                }
            }
        }


        if (type == "phone_number") {
            if (e.trim() !== "") {
                let modifiedString = ValidateUtility.deleteAndBefore(e, dialCodeObj.dialCode);
                let countryCodeValue = dialCodeObj.countryCode.toUpperCase();
                const phoneNumber = parsePhoneNumber(modifiedString, countryCodeValue);
                setPhoneNumberCountryCode(dialCodeObj.countryCode)
                setPhoneNumberDialCode(dialCodeObj.dialCode)
                if (phoneNumber && phoneNumber.isValid()) {
                    tempFormData["phone_number"] = e
                    tempFormDataError['phone_number'] = ""
                } else {
                    tempFormData["phone_number"] = e
                    tempFormDataError['phone_number'] = "Enter valid contact number"
                }
            } else {
                tempFormData["phone_number"] = "";
                tempFormDataError['phone_number'] = "Required field";
            }
        }

        if (type == "customer") {
            setSelectedCustomer(e)
            tempFormData['name'] = e.name;
            tempFormData['last_name'] = e.last_name;
            tempFormData["email"] = [{ 'email': e.email }];
            tempFormData["phone_number"] = e.phone_number;
            tempFormData["customer_gstin_no"] = e.customer_gstin_no;
            tempFormData["company_name"] = e.company_name;
            tempFormData["company_address"] = e.company_address;
            tempFormDataError['name'] = ""
            tempFormDataError['last_name'] = ""
            tempFormDataError["email"] = [{ 'email': "" }]
            tempFormDataError["phone_number"] = ""
            tempFormDataError["customer_gstin_no"] = ""
            tempFormDataError["company_name"] = ""
            tempFormDataError["company_address"] = ""
        }
        setFormData(tempFormData)
        setFormDataError(tempFormDataError)
    }

    //validate before booking create
    const validConfirmBookingModalFn = () => {
        let valid = true;
        let tempFormDataError = { ...formDataError }

        if ((formData.name).trim() == "") {
            valid = false;
            tempFormDataError['name'] = "Required Field"
        }

        if ((formData.last_name).trim() == "") {
            valid = false;
            tempFormDataError['last_name'] = "Required Field"
        }

        formData.email.forEach((emailObj, index) => {
            const email = emailObj.email.trim();

            if (index == 0) {
                if (tempFormDataError['email'][0]['email'] == 'User already exits in management role') {
                    valid = false;
                }
            }
            if (email === "") {
                valid = false;
                tempFormDataError['email'][index]['email'] = "Required field";
            } else if (!isValidEmail(email)) {
                valid = false;
                tempFormDataError['email'][index]['email'] = "Enter valid email";
            }
        });

        if (formData.phone_number == "" || formData.phone_number == null) {
            tempFormDataError['phone_number'] = "Required field"
            valid = false
        } else {
            let modifiedString = ValidateUtility.deleteAndBefore(formData.phone_number, phoneNumberDialCode);
            let countryCodeValue = phoneNumberCountryCode.toUpperCase();
            const phoneNumber = parsePhoneNumber(modifiedString, countryCodeValue);
            if (phoneNumber && phoneNumber.isValid()) {
                tempFormDataError['phone_number'] = ""
            } else {
                tempFormDataError['phone_number'] = "Enter valid contact number"
                valid = false
            }
        }

        if (formData.customer_gstin_no != "") {
            let gstnValidate = ValidateUtility.validate_gstn_Number(formData.customer_gstin_no);
            if (!gstnValidate) {
                valid = false;
                tempFormDataError['customer_gstin_no'] = "Please enter a correct GSTIN No"
            }
        }
        setFormDataError(tempFormDataError)
        return valid;
    }

    const isValidEmail = (email) => {
        const emailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
        return emailRegex.test(email);
    };

    useEffect(() => {
    }, [formData])


    //set currency and price distribution page data
    useEffect(() => {
        let paymentDetailsRoomTypeData = props.paymentDetailsData?.room_type_data;
        if (paymentDetailsRoomTypeData) {
            Object.keys(paymentDetailsRoomTypeData).forEach((roomId, idx) => {
                Object.keys(paymentDetailsRoomTypeData[roomId]).forEach((amount, idx) => {
                    let currency = paymentDetailsRoomTypeData[roomId][amount].currency;
                    setCurrencyName(currency)
                });
            });
        }

        if (Object.keys(props.paymentDetailsData).length > 0) {
            setPaymentDetailsDataSet(props.paymentDetailsData);
        }
    }, [props.paymentDetailsData])


    //Room type information header ui
    const paymentPriceDistributionUi = () => {
        let room_type_data_arr = [];
        if ((paymentDetailsDataSet.hasOwnProperty('room_type_data') && paymentDetailsDataSet.room_type_data && Object.keys(paymentDetailsDataSet.room_type_data).length > 0) || (paymentDetailsDataSet.hasOwnProperty('previous_booking_hash') && paymentDetailsDataSet.previous_booking_hash && Object.keys(paymentDetailsDataSet.previous_booking_hash).length > 0)) {
            let paymentDetailsRoomTypeData = paymentDetailsDataSet.room_type_data;
            let paymentDetailsRoomTypeData_old = paymentDetailsDataSet.hasOwnProperty('previous_booking_hash') && paymentDetailsDataSet.previous_booking_hash ? paymentDetailsDataSet.previous_booking_hash : {};


            room_type_data_arr.push(
                <div className="paymentDetailsSectionBoxRow" >
                    <div className="paymentDetailsSectionDateRowBox d-table">
                        <div className='paymentDetailsTitle'>{Utility.capitalizeFirstLetterOfEachWord('Room type')}</div>
                        <div className='paymentDetailsTitle'>{Utility.capitalizeFirstLetterOfEachWord('Booking Details')}<span>Check-in - Check-out</span></div>
                        <div className='paymentDetailsTitle'>{Utility.capitalizeFirstLetterOfEachWord('Total Duration')}</div>
                        <div className='paymentDetailsTitle'>{Utility.capitalizeFirstLetterOfEachWord('Total Room (s)')}</div>
                        <div className='paymentDetailsTitle'>{Utility.capitalizeFirstLetterOfEachWord('Price / Night')} ({Utility.getCurrencySymbol(currencyName)})</div>
                        <div className='paymentDetailsTitle'>{Utility.capitalizeFirstLetterOfEachWord('Particular')}</div>
                        <div className='paymentDetailsTitle text-right amountLabelSpace'>{`${Utility.capitalizeFirstLetterOfEachWord('Amount')} (${Utility.getCurrencySymbol(currencyName) != null ? Utility.getCurrencySymbol(currencyName) : ''})`}</div>
                    </div>
                    <div className="paymentDetailsSectionDateRowDataBox">
                        {Object.keys(paymentDetailsRoomTypeData_old).length > 0 ? roomTypePerdayWisePrice(paymentDetailsRoomTypeData_old, false) : null}
                        {roomTypePerdayWisePrice(paymentDetailsRoomTypeData, true)}
                    </div>
                </div>
            );
        }
        return room_type_data_arr;
    }

    //check all header option for food onchange event
    const checkAllFood = (e, type) => {
        let paymentDetailsDataSetTemp = { ...paymentDetailsDataSet }
        Object.keys(paymentDetailsDataSetTemp.fooding_hash).forEach(date => {
            for (const ageRange in paymentDetailsDataSetTemp.fooding_hash[date]) {
                if (type == 'is_breakfast' && paymentDetailsDataSetTemp.fooding_hash[date][ageRange].is_breakfast_available_original == 1) {
                    paymentDetailsDataSetTemp.fooding_hash[date][ageRange].is_breakfast_available = e.target.checked ? 1 : 0;
                }
                if (type == 'is_lunch' && paymentDetailsDataSetTemp.fooding_hash[date][ageRange].is_lunch_available_original == 1) {
                    paymentDetailsDataSetTemp.fooding_hash[date][ageRange].is_lunch_available = e.target.checked ? 1 : 0;
                }
                if (type == 'is_dinner' && paymentDetailsDataSetTemp.fooding_hash[date][ageRange].is_dinner_available_original == 1) {
                    paymentDetailsDataSetTemp.fooding_hash[date][ageRange].is_dinner_available = e.target.checked ? 1 : 0;
                }
            }
        });
        setPaymentDetailsDataSet(paymentDetailsDataSetTemp)
    }

    //check fooding availability
    const checkAvailability = (data) => {
        const allBreakfastNA = checkAvailabilityMeal(data, 'is_breakfast_available_original');
        const allLunchNA = checkAvailabilityMeal(data, 'is_lunch_available_original');
        const allDinnerNA = checkAvailabilityMeal(data, 'is_dinner_available_original');
        return {
            all_breakfast_na: allBreakfastNA,
            all_lunch_na: allLunchNA,
            all_dinner_na: allDinnerNA
        };
    };
    const checkAvailabilityMeal = (data, availabilityKey) => {
        for (const date in data) {
            if (data.hasOwnProperty(date)) {
                for (const category in data[date]) {
                    if (data[date].hasOwnProperty(category)) {
                        if (data[date][category][availabilityKey] !== 0) {
                            return false;
                        }
                    }
                }
            }
        }
        return true;
    };

    //check per row fooding availability
    const checkBoxAvailabilityFn = (data) => {
        let availability = {
            all_breakfast: true,
            all_lunch: true,
            all_dinner: true
        };

        for (let date in data) {
            if (data.hasOwnProperty(date)) {
                for (let category in data[date]) {
                    if (data[date].hasOwnProperty(category)) {
                        let categoryData = data[date][category];

                        if (categoryData.is_breakfast_available_original === 1 && categoryData.is_breakfast_available !== 1) {
                            availability.all_breakfast = false;
                        }

                        if (categoryData.is_lunch_available_original === 1 && categoryData.is_lunch_available !== 1) {
                            availability.all_lunch = false;
                        }

                        if (categoryData.is_dinner_available_original === 1 && categoryData.is_dinner_available !== 1) {
                            availability.all_dinner = false;
                        }
                    }
                }
            }
        }
        return availability;
    };


    //fooding details herder ui
    const foodPriceDistributionUi = () => {
        let fooding_arr = [];
        if ((paymentDetailsDataSet.hasOwnProperty('fooding_hash') && paymentDetailsDataSet.fooding_hash && Object.keys(paymentDetailsDataSet.fooding_hash).length > 0) || (paymentDetailsDataSet.hasOwnProperty('previous_food_hash') && paymentDetailsDataSet.previous_food_hash && Object.keys(paymentDetailsDataSet.previous_food_hash).length > 0)) {
            let paymentDetailsRoomTypeFoodingData = paymentDetailsDataSet.fooding_hash;
            let paymentDetailsRoomTypeFoodingData_old = paymentDetailsDataSet.hasOwnProperty('previous_food_hash') && paymentDetailsDataSet.previous_food_hash ? paymentDetailsDataSet.previous_food_hash : {};
            fooding_arr.push(
                <>
                    <div className="paymentDetailsSectionBoxRow paymentDetailsSectionBoxRowFood" >
                        <div className='food-details-label'>{Utility.capitalizeFirstLetterOfEachWord('Food details')}
                        </div>
                        <div className="paymentDetailsSectionDateRowBox">
                            <div className='paymentDetailsTitle datetitle'>{Utility.capitalizeFirstLetterOfEachWord('date')}</div>
                            <div className='paymentDetailsTitle paymentDetailsTitleSmall middsize'>{Utility.capitalizeFirstLetterOfEachWord('head count')}</div>
                            <div className='paymentDetailsTitle paymentDetailsTitleSmall breakfasttitle'>
                                {checkAvailability(paymentDetailsRoomTypeFoodingData).all_breakfast_na == false && <label className="checkbox_meal all_checked">
                                    <input type="checkbox"
                                        checked={checkBoxAvailabilityFn(paymentDetailsRoomTypeFoodingData).all_breakfast}
                                        onChange={(e) => checkAllFood(e, 'is_breakfast')}
                                    />
                                    <span className="checkmarktickmeal"></span>
                                </label>}
                                {Utility.capitalizeFirstLetterOfEachWord('breakfast')}
                            </div>


                            <div className='paymentDetailsTitle paymentDetailsTitleLarge bigsize'>{Utility.capitalizeFirstLetterOfEachWord('breakfast / person')} ({Utility.getCurrencySymbol(currencyName)})</div>

                            <div className='paymentDetailsTitle paymentDetailsTitleSmall'>
                                {checkAvailability(paymentDetailsRoomTypeFoodingData).all_lunch_na == false && <label className="checkbox_meal all_checked">
                                    <input type="checkbox"
                                        checked={checkBoxAvailabilityFn(paymentDetailsRoomTypeFoodingData).all_lunch}
                                        onChange={(e) => checkAllFood(e, 'is_lunch')}
                                    />
                                    <span className="checkmarktickmeal"></span>
                                </label>}
                                {Utility.capitalizeFirstLetterOfEachWord('lunch')}
                            </div>

                            <div className='paymentDetailsTitle paymentDetailsTitleLarge'>{Utility.capitalizeFirstLetterOfEachWord('lunch / person')} ({Utility.getCurrencySymbol(currencyName)})</div>
                            <div className='paymentDetailsTitle paymentDetailsTitleSmall'>
                                {checkAvailability(paymentDetailsRoomTypeFoodingData).all_dinner_na == false && <label className="checkbox_meal all_checked">
                                    <input type="checkbox"
                                        checked={checkBoxAvailabilityFn(paymentDetailsRoomTypeFoodingData).all_dinner}
                                        onChange={(e) => checkAllFood(e, 'is_dinner')}
                                    />
                                    <span className="checkmarktickmeal"></span>
                                </label>}
                                {Utility.capitalizeFirstLetterOfEachWord('dinner')}
                            </div>
                            <div className='paymentDetailsTitle paymentDetailsTitleLarge bigsize2'>{Utility.capitalizeFirstLetterOfEachWord('dinner / person')} ({Utility.getCurrencySymbol(currencyName)})</div>
                            <div className='paymentDetailsTitle paymentDetailsTitleSmall middsize descriptionViewTitleInner'>
                            </div>
                            <div className='paymentDetailsTitle text-right amountLabelSpace'>{`${Utility.capitalizeFirstLetterOfEachWord('Amount')} (${Utility.getCurrencySymbol(currencyName) != null ? Utility.getCurrencySymbol(currencyName) : ''})`}</div>
                        </div>
                        <div className="paymentDetailsSectionDateRowDataBox">
                            {Object.keys(paymentDetailsRoomTypeFoodingData_old).length > 0 ? roomTypeWisefoodPrice(paymentDetailsRoomTypeFoodingData_old, false) : null}
                            {roomTypeWisefoodPrice(paymentDetailsRoomTypeFoodingData, true)}
                        </div>
                    </div>
                    <div className='singleRowBox'>
                        <div className='singleRowBoxInnerLeft boldText'>{Utility.capitalizeFirstLetterOfEachWord('Total')}:</div>
                        <div className='singleRowBoxInnerRight text-right boldText'>{Utility.getCurrencySymbol(currencyName)} {Number(calculate_roomrate_amount().foodRateAmountwithoutgst).toFixed(2)}</div>
                    </div>
                    {paymentDetailsDataSet.hasOwnProperty('is_igst_enable') && !paymentDetailsDataSet.is_igst_enable ?
                        <>
                            {parseFloat(paymentDetailsDataSet?.fcgst) !== 0 && <div className='singleRowBox'>
                                <div className='singleRowBoxInnerLeft'>CGST @ {parseFloat(paymentDetailsDataSet?.fcgst).toFixed(2)}% :</div>
                                <div className='singleRowBoxInnerRight'>{Utility.getCurrencySymbol(currencyName)} {Number(calculate_roomrate_amount().food_cgst_amount).toFixed(2)}</div>
                            </div>}
                            {parseFloat(paymentDetailsDataSet?.fsgst) !== 0 && <div className='singleRowBox'>
                                <div className='singleRowBoxInnerLeft'>SGST @ {parseFloat(paymentDetailsDataSet?.fsgst).toFixed(2)}% :</div>
                                <div className='singleRowBoxInnerRight'>{Utility.getCurrencySymbol(currencyName)} {Number(calculate_roomrate_amount().food_sgst_amount).toFixed(2)}</div>
                            </div>}
                        </> :
                        <>
                            {parseFloat(paymentDetailsDataSet?.fgst) !== 0 && <div className='singleRowBox'>
                                <div className='singleRowBoxInnerLeft'>IGST @ {parseFloat(paymentDetailsDataSet?.fgst).toFixed(2)}% :</div>
                                <div className='singleRowBoxInnerRight'>{Utility.getCurrencySymbol(currencyName)} {Number(calculate_roomrate_amount().food_gst_amount).toFixed(2)}</div>
                            </div>}
                        </>
                    }
                    <div className='singleRowBox'>
                        <div className='singleRowBoxInnerLeft boldText'>{Utility.capitalizeFirstLetterOfEachWord('Total payable amount (Food)')}:</div>
                        <div className='singleRowBoxInnerRight boldText'>{Utility.getCurrencySymbol(currencyName)} {Number(calculate_roomrate_amount().foodamount).toFixed(2)}</div>
                    </div>
                </>
            );
        }

        return fooding_arr;
    }

    //fooding detils value ui
    const roomTypeWisefoodPrice = (data, is_new = true) => {
        let arrUi = [];
        let prevDate = '';

        Object.keys(data).map((dateKey, idx) => {
            let innerArrUi = [];

            for (const ageRange in data[dateKey]) {
                let newdate = ''
                if (prevDate != dateKey) {
                    prevDate = dateKey
                    newdate = dateKey
                }
                let room_type_details = data[dateKey][ageRange];
                let breakfastRate = "0";
                let lunchRate = "0";
                let dinnerRate = "0";
                let is_breakfast = false;
                let is_lunch = false;
                let is_dinner = false;

                let breakfastAmount = "0";
                let lunchAmount = "0";
                let dinnerAmount = "0";

                let headCount = 0;
                let description = "";

                headCount = room_type_details.head_count
                description = room_type_details.description;

                is_breakfast = room_type_details.is_breakfast_available == 0 ? false : true;
                breakfastRate = room_type_details.breakfast_rate.toString()
                if (is_breakfast) {
                    breakfastAmount = room_type_details.breakfast_total == undefined ? '0' : room_type_details.breakfast_total.toString();
                }

                is_lunch = room_type_details.is_lunch_available == 0 ? false : true;
                lunchRate = room_type_details.lunch_rate.toString()
                if (is_lunch) {
                    lunchAmount = room_type_details.lunch_total.toString();
                }

                is_dinner = room_type_details.is_dinner_available == 0 ? false : true;
                dinnerRate = room_type_details.dinner_rate.toString()
                if (is_dinner) {
                    dinnerAmount = room_type_details.dinner_total.toString();
                }

                innerArrUi.push(<div className={is_new ? "paymentDetailsDataBox currentRow" : "paymentDetailsDataBox previousRow"} key={dateKey + '-' + ageRange} style={noborder}>
                    <div className='paymentDetailsValue'>{newdate != '' ? <span className='dateField'>{moment(newdate).format("DD-MM-YYYY")}</span> : ''}{ageRange.includes("adult") ? "Adult" : `Children(${ageRange})`}</div>
                    <div className='paymentDetailsValue paymentDetailsValueSmall middsize'>{headCount}</div>

                    {room_type_details.is_breakfast_available_original == 1 ?
                        <div className='paymentDetailsValue paymentDetailsValueSmall breakfasttitle'>
                            <label className="checkbox_meal">
                                <input type="checkbox"
                                    checked={is_breakfast}
                                    onChange={(e) => handelChangeFoodRateChange(e, dateKey, ageRange, 'breakfastIncluded')}
                                />
                                <span className="checkmarktickmeal"></span>
                            </label>
                        </div> : <div className='paymentDetailsValue paymentDetailsValueSmall'><span style={{ color: 'red' }}>N/A</span></div>}

                    {room_type_details.is_breakfast_available_original == 1 ?
                        <div className='paymentDetailsValue paymentDetailsValueLarge bigsize editIconBox'>
                            {is_new ? <ErrorBoundary title="CustomInput Error">
                                <CustomInput
                                    parentClassName="input_field_inner"
                                    labelName="Breakfast Rate"
                                    name="breakfast_rate"
                                    type="text"
                                    value={breakfastRate}
                                    labelPresent={false}
                                    onChange={(e) => handelChangeFoodRateChange(e, dateKey, ageRange, 'breakfast_rate')}
                                />
                            </ErrorBoundary> :
                                <label className="checkbox_meal">
                                    {Number(breakfastRate).toFixed(2)}
                                </label>
                            }
                        </div> : <div className='paymentDetailsValue paymentDetailsValueLarge bigsize editIconBox'><span style={{ color: 'red' }}>N/A</span></div>}
                    {room_type_details.is_lunch_available_original == 1 ?
                        <div className='paymentDetailsValue paymentDetailsValueSmall'>
                            <label className="checkbox_meal">
                                <input type="checkbox"
                                    checked={is_lunch}
                                    onChange={(e) => handelChangeFoodRateChange(e, dateKey, ageRange, 'lunchIncluded')}
                                />
                                <span className="checkmarktickmeal"></span>
                            </label>
                        </div> : <div className='paymentDetailsValue paymentDetailsValueSmall'><span style={{ color: 'red' }}>N/A</span></div>}
                    {room_type_details.is_lunch_available_original == 1 ?
                        <div className='paymentDetailsValue paymentDetailsValueLarge editIconBox'>
                            {is_new ? <ErrorBoundary title="CustomInput Error">
                                <CustomInput
                                    parentClassName="input_field_inner"
                                    labelName="Lunch Rate"
                                    name="lunch_rate"
                                    type="text"
                                    value={lunchRate}
                                    labelPresent={false}
                                    onChange={(e) => handelChangeFoodRateChange(e, dateKey, ageRange, 'lunch_rate')}
                                />
                            </ErrorBoundary> :
                                <label className="checkbox_meal">
                                    {Number(lunchRate).toFixed(2)}
                                </label>
                            }
                        </div> : <div className='paymentDetailsValue paymentDetailsValueLarge editIconBox'><span style={{ color: 'red' }}>N/A</span></div>}
                    {room_type_details.is_dinner_available_original == 1 ?
                        <div className='paymentDetailsValue paymentDetailsValueSmall'>
                            <label className="checkbox_meal">
                                <input type="checkbox"
                                    onChange={(e) => handelChangeFoodRateChange(e, dateKey, ageRange, 'dinnerIncluded')}
                                    checked={is_dinner}
                                />
                                <span className="checkmarktickmeal"></span>
                            </label>
                        </div> : <div className='paymentDetailsValue paymentDetailsValueSmall'><span style={{ color: 'red' }}>N/A</span></div>}
                    {room_type_details.is_dinner_available_original == 1 ?
                        <div className='paymentDetailsValue paymentDetailsValueLarge bigsize2 editIconBox'>
                            {is_new ? <ErrorBoundary title="CustomInput Error">
                                <CustomInput
                                    parentClassName="input_field_inner"
                                    labelName="Dinner Rate"
                                    name="dinner_rate"
                                    type="text"
                                    value={dinnerRate}
                                    labelPresent={false}
                                    onChange={(e) => handelChangeFoodRateChange(e, dateKey, ageRange, 'dinner_rate')}
                                />
                            </ErrorBoundary> :
                                <label className="checkbox_meal">
                                    {Number(dinnerRate).toFixed(2)}
                                </label>
                            }
                        </div> : <div className='paymentDetailsValue paymentDetailsValueLarge bigsize2 editIconBox'><span style={{ color: 'red' }}>N/A</span></div>}
                    <div className='paymentDetailsValue paymentDetailsValueLarge middsize descriptionViewBtnInner'>
                    </div>
                    <div className='paymentDetailsValue text-right amountValueSpace'>{Utility.getCurrencySymbol(currencyName)} {parseFloat(Number(Number(breakfastAmount) + Number(lunchAmount) + Number(dinnerAmount))).toFixed(2)}</div>
                </div>)
            }
            arrUi.push(<div className={is_new ? "date-group" : "date-group date-group-old"} key={`group-${dateKey}`}>{innerArrUi}</div>);
            arrUi.push(<div style={divStyle}> </div>);
        })
        return arrUi
    }

    const divStyle = {
        border: '0.1rem solid #eef0f5'
    };

    const noborder = {
        border: '0.1rem solid #eef0f5'
    };

    //room type wise price value ui
    var room_type_amount_wise_price = {}
    const roomTypePerdayWisePrice = (data, is_new = true) => {
        let arrUi = [];
        let dayWiseRoomDetails = props.hotelRoomBookDataSet[0].date_wise_room_details;
        Object.keys(data).map((room_type_id, idx) => {
            Object.keys(data[room_type_id]).map((amount, i) => {
                let room_type_details = data[room_type_id][amount];
                let allDates = Object.keys(room_type_details.date_wise_price);
                for (let u = 0; u < allDates.length; u++) {
                    let date = allDates[u]
                    let room_type_id_original = room_type_id.split('-')[0]
                    dayWiseRoomDetails[date][room_type_id_original.toString()]["override_price_per_room"] = room_type_details.perday_rent;
                }
                room_type_amount_wise_price[room_type_id + '_' + amount] = room_type_details.room_rate
                arrUi.push(<div className={is_new ? 'paymentDetailsDataBox currentRow' : 'paymentDetailsDataBox previousRow'} key={idx}>
                    <div className='paymentDetailsValue'>{room_type_details.room_type_name}
                    </div>
                    <div className='paymentDetailsValue'>{`${moment(room_type_details.book_from).format('DD-MM-YYYY')} - ${moment(room_type_details.book_to).format('DD-MM-YYYY')}`}</div>
                    <div className='paymentDetailsValue'>{`${room_type_details.day_night}`}</div>
                    <div className='paymentDetailsValue'>{`${room_type_details?.room_count}`}</div>
                    <div className='paymentDetailsValue editIconBox perDayRateBox'>
                        {is_new ? <ErrorBoundary title="CustomInput Error">
                            <CustomInput
                                parentClassName="input_field_inner"
                                labelName="Per Night Rate"
                                name="per_day_rate"
                                type="text"
                                value={room_type_details.perday_rent}
                                labelPresent={false}
                                onChange={(e) => handelChangeRoomRentChangeFn(e, room_type_id, allDates, amount)}
                            />
                        </ErrorBoundary> :
                            <label>
                                {Number(room_type_details.perday_rent).toFixed(2)}</label>
                        }
                    </div>
                    <div className='paymentDetailsValue'>{`${Utility.getCurrencySymbol(currencyName)} ${room_type_details.perday_rent == "" ? "0.00" : Number(room_type_details.perday_rent).toFixed(2)} @ ${room_type_details.day_count} ${parseInt(room_type_details.day_count) > 1 ? 'Nights' : "Night"} @ ${room_type_details.room_count} ${parseInt(room_type_details.room_count) > 1 ? 'Rooms' : "Room"}  `}</div>
                    <div className='paymentDetailsValue text-right amountValueSpace'>{Utility.getCurrencySymbol(currencyName)} {Number(room_type_details.room_rate).toFixed(2)}</div>

                </div>)

            })
        })
        props.hotelRoomBookDataSet[0].date_wise_room_details = dayWiseRoomDetails;
        return arrUi
    }


    //Room rate change onchange event
    const handelChangeRoomRentChangeFn = (e, room_type_id, allDates, amount) => {
        let perDayRoomAmount = 0;
        var expr = /^\d{0,8}(\.\d{0,2})?$/;
        if (!expr.test(e.target.value)) {

        } else {
            let paymentDetailsDataSetTemp = { ...paymentDetailsDataSet }
            paymentDetailsDataSetTemp.room_type_data[room_type_id][amount].perday_rent = e.target.value == "" ? "" : e.target.value;
            let dayCount = paymentDetailsDataSetTemp.room_type_data[room_type_id][amount].day_count;
            perDayRoomAmount = parseFloat(e.target.value == "" ? 0 : e.target.value) * parseInt(dayCount);
            paymentDetailsDataSetTemp.room_type_data[room_type_id][amount].room_rate = perDayRoomAmount;
            setPaymentDetailsDataSet(paymentDetailsDataSetTemp);

            let dayWiseRoomDetails = props.hotelRoomBookDataSet[0].date_wise_room_details;
            for (let u = 0; u < allDates.length; u++) {
                let date = allDates[u]
                let room_type_id_original = room_type_id.split('-')[0]
                dayWiseRoomDetails[date][room_type_id_original.toString()]["override_price_per_room"] = e.target.value;
            }
            props.hotelRoomBookDataSet[0].date_wise_room_details = dayWiseRoomDetails;
        }
    }

    //calculate price distribution full page amount 
    const calculate_roomrate_amount = () => {
        let paymentDetailsDataSetTemp = { ...paymentDetailsDataSet }
        let hotelTaxData = 0;
        let roomRateAmountwithoutgst = 0;
        let cgstPercentage = 0;
        let sgstPercentage = 0;
        let igstPercentage = 0;
        let cgstAmount = 0;
        let sgstAmount = 0;
        let igstAmount = 0;

        //food
        let foodRateAmountwithoutgst = 0;
        let figst = paymentDetailsDataSetTemp.fgst
        let fsgst = paymentDetailsDataSetTemp.fsgst
        let fcgst = paymentDetailsDataSetTemp.fcgst
        let food_cgst_amount = 0
        let food_sgst_amount = 0
        let food_gst_amount = 0

        //additional payable notes
        let notationRateAmountwithoutgst = 0;
        let notation_igst = paymentDetailsDataSetTemp.notation_igst
        let notation_sgst = paymentDetailsDataSetTemp.notation_sgst
        let notation_cgst = paymentDetailsDataSetTemp.notation_cgst
        let notation_cgst_amount = 0
        let notation_sgst_amount = 0
        let notation_gst_amount = 0


        if (Object.keys(paymentDetailsDataSetTemp).length > 0) {
            hotelTaxData = paymentDetailsDataSetTemp.hotel_tax.length > 0 ? Number(paymentDetailsDataSetTemp.hotel_tax[0].gst_percent) : 0;
            let date_wise_room_details = paymentDetailsDataSetTemp.room_type_data;
            Object.keys(date_wise_room_details).forEach((room_type_id, i) => {
                Object.keys(date_wise_room_details[room_type_id]).forEach((amount, idx) => {
                    roomRateAmountwithoutgst += Number(date_wise_room_details[room_type_id][amount].perday_rent) * Number(date_wise_room_details[room_type_id][amount].day_count) * Number(date_wise_room_details[room_type_id][amount].room_count)
                })
            })
            let previous_booking_hash = paymentDetailsDataSetTemp.previous_booking_hash ? paymentDetailsDataSetTemp.previous_booking_hash : {}
            Object.keys(previous_booking_hash).forEach((room_type_id, i) => {
                Object.keys(previous_booking_hash[room_type_id]).forEach((amount, idx) => {
                    roomRateAmountwithoutgst += Number(previous_booking_hash[room_type_id][amount].perday_rent) * Number(previous_booking_hash[room_type_id][amount].day_count) * Number(previous_booking_hash[room_type_id][amount].room_count)
                })
            })
            let hotelTaxDataSet = paymentDetailsDataSetTemp.hotel_tax.find((tax) => {
                let rangeFrom = Number(tax.range_from);
                let rangeUpto = Number(tax.range_upto);
                return rangeFrom <= roomRateAmountwithoutgst && roomRateAmountwithoutgst <= rangeUpto;
            });

            if (hotelTaxDataSet) {
                hotelTaxData = hotelTaxDataSet.gst_percent;
            } else {
                let min_gst_percentage = 0;
                let max_gst_percentage = 0;

                if (paymentDetailsDataSetTemp.hotel_tax.length > 0) {
                    min_gst_percentage = paymentDetailsDataSetTemp.hotel_tax[0].gst_percent;
                    max_gst_percentage = paymentDetailsDataSetTemp.hotel_tax[paymentDetailsDataSetTemp.hotel_tax.length - 1].gst_percent;
                }
                let maxRangeTo = paymentDetailsDataSetTemp.hotel_tax.reduce((max, entry) => Math.max(max, Number(entry.range_upto)), -Infinity);
                let minRangeFrom = paymentDetailsDataSetTemp.hotel_tax.reduce((min, entry) => Math.min(min, Number(entry.range_from)), Infinity);
                let hotelTaxData = 0;
                if (roomRateAmountwithoutgst <= minRangeFrom) {
                    hotelTaxData = min_gst_percentage;
                }
                if (roomRateAmountwithoutgst >= maxRangeTo) {
                    hotelTaxData = max_gst_percentage;
                }
            }
            /*********Food */
            let date_wise_food_details = paymentDetailsDataSetTemp.fooding_hash;
            Object.keys(date_wise_food_details).forEach((date, i) => {
                for (const ageRange in date_wise_food_details[date]) {
                    if (date_wise_food_details[date][ageRange]['is_breakfast_available'] == 1) {
                        foodRateAmountwithoutgst += Number(date_wise_food_details[date][ageRange]['breakfast_rate']) * Number(date_wise_food_details[date][ageRange]['head_count'])
                    }
                    if (date_wise_food_details[date][ageRange]['is_lunch_available'] == 1) {
                        foodRateAmountwithoutgst += Number(date_wise_food_details[date][ageRange]['lunch_rate']) * Number(date_wise_food_details[date][ageRange]['head_count'])
                    }
                    if (date_wise_food_details[date][ageRange]['is_dinner_available'] == 1) {
                        foodRateAmountwithoutgst += Number(date_wise_food_details[date][ageRange]['dinner_rate']) * Number(date_wise_food_details[date][ageRange]['head_count'])
                    }
                }
            })

            let previous_food_hash = paymentDetailsDataSetTemp.previous_food_hash ? paymentDetailsDataSetTemp.previous_food_hash : {};
            Object.keys(previous_food_hash).forEach((date, i) => {
                for (const ageRange in previous_food_hash[date]) {
                    if (previous_food_hash[date][ageRange]['is_breakfast_available'] == 1) {
                        foodRateAmountwithoutgst += Number(previous_food_hash[date][ageRange]['breakfast_rate']) * Number(previous_food_hash[date][ageRange]['head_count'])
                    }
                    if (previous_food_hash[date][ageRange]['is_lunch_available'] == 1) {
                        foodRateAmountwithoutgst += Number(previous_food_hash[date][ageRange]['lunch_rate']) * Number(previous_food_hash[date][ageRange]['head_count'])
                    }
                    if (previous_food_hash[date][ageRange]['is_dinner_available'] == 1) {
                        foodRateAmountwithoutgst += Number(previous_food_hash[date][ageRange]['dinner_rate']) * Number(previous_food_hash[date][ageRange]['head_count'])
                    }
                }
            })

            let payableServiceListTemp = payableServiceList.slice();
            if (payableServiceListTemp.length > 0) {
                payableServiceListTemp.map((payableNotes, idx) => {
                    notationRateAmountwithoutgst += Number(payableNotes.amount)
                })
            }
        }
        notation_cgst_amount = Number(Number(Number(notationRateAmountwithoutgst) * Number(notation_cgst) / 100).toFixed(2));
        notation_sgst_amount = Number(Number(Number(notationRateAmountwithoutgst) * Number(notation_sgst) / 100).toFixed(2));
        notation_gst_amount = notation_cgst_amount + notation_sgst_amount;
        let totalPayableNotationAmount = Number(Number(Number(notationRateAmountwithoutgst) + Number(notation_gst_amount)).toFixed(2));
        food_cgst_amount = Number(Number(Number(foodRateAmountwithoutgst) * Number(fcgst) / 100).toFixed(2));
        food_sgst_amount = Number(Number(Number(foodRateAmountwithoutgst) * Number(fsgst) / 100).toFixed(2));
        food_gst_amount = food_cgst_amount + food_sgst_amount;
        let totalPayableFoodAmount = Number(Number(Number(foodRateAmountwithoutgst) + Number(food_gst_amount)).toFixed(2));
        sgstPercentage = Number(Number(hotelTaxData / 2).toFixed(2));
        cgstPercentage = Number(Number(sgstPercentage).toFixed(2));
        igstPercentage = Number(Number(hotelTaxData).toFixed(2));
        cgstAmount = Number(Number(Number(roomRateAmountwithoutgst) * Number(cgstPercentage) / 100).toFixed(2));
        sgstAmount = Number(Number(Number(roomRateAmountwithoutgst) * Number(sgstPercentage) / 100).toFixed(2));
        igstAmount = cgstAmount + sgstAmount;
        let totalPayableAmount = Number(Number(Number(roomRateAmountwithoutgst) + Number(igstAmount)).toFixed(2));
        const originalValue = totalPayableAmount;
        let fullbookingamount = Number(Number(originalValue).toFixed(2)) + Number(Number(totalPayableFoodAmount).toFixed(2)) + Number(Number(totalPayableNotationAmount).toFixed(2));
        let totalPayableRoundedAmount = Math.floor(fullbookingamount).toFixed(2);
        let roundOffAmount = Number(totalPayableRoundedAmount) - Number(fullbookingamount);
        let roomRentHash = { 'roomRateAmountwithoutgst': roomRateAmountwithoutgst, "sgstPercentage": sgstPercentage, "cgstPercentage": cgstPercentage, "igstPercentage": igstPercentage, "cgstAmount": cgstAmount, "sgstAmount": sgstAmount, "igstAmount": igstAmount, "totalPayableAmount": originalValue, "round_off": "", 'fullbookingamount': fullbookingamount, 'foodamount': totalPayableFoodAmount, 'food_cgst_amount': food_cgst_amount, 'food_sgst_amount': food_sgst_amount, 'food_gst_amount': food_gst_amount, "foodRateAmountwithoutgst": foodRateAmountwithoutgst, 'notationamount': totalPayableNotationAmount, 'notation_cgst_amount': notation_cgst_amount, 'notation_sgst_amount': notation_sgst_amount, 'notation_gst_amount': notation_gst_amount, "notationRateAmountwithoutgst": notationRateAmountwithoutgst, "notation_igst": notation_igst, "notation_cgst": notation_cgst, "notation_sgst": notation_sgst, "totalPayableRoundedAmount": totalPayableRoundedAmount, "roundOffAmount": roundOffAmount }
        return roomRentHash;
    }

    const roundAndDifference = (value) => {
        const roundedValue = Math.round(value);
        const difference = value - roundedValue;

        return {
            roundedValue,
            difference
        };
    }


    //Food rate change onChange function
    const handelChangeFoodRateChange = (e, dateKey, ageRange, type) => {
        let paymentDetailsDataSetTemp = { ...paymentDetailsDataSet }
        if (Object.keys(paymentDetailsDataSetTemp).length > 0) {
            var expr = /^\d{0,8}(\.\d{0,2})?$/;
            if (!expr.test(e.target.value)) {

            } else {
                if (type == "breakfast_rate") {
                    paymentDetailsDataSetTemp["fooding_hash"][dateKey][ageRange].breakfast_rate = e.target.value;
                    paymentDetailsDataSetTemp["fooding_hash"][dateKey][ageRange].breakfast_total = Number(e.target.value) * Number(paymentDetailsDataSetTemp["fooding_hash"][dateKey][ageRange].head_count);
                }
                if (type == "lunch_rate") {
                    paymentDetailsDataSetTemp["fooding_hash"][dateKey][ageRange].lunch_rate = e.target.value;
                    paymentDetailsDataSetTemp["fooding_hash"][dateKey][ageRange].lunch_total = Number(e.target.value) * Number(paymentDetailsDataSetTemp["fooding_hash"][dateKey][ageRange].head_count);
                }
                if (type == "dinner_rate") {
                    paymentDetailsDataSetTemp["fooding_hash"][dateKey][ageRange].dinner_rate = e.target.value;
                    paymentDetailsDataSetTemp["fooding_hash"][dateKey][ageRange].dinner_total = Number(e.target.value) * Number(paymentDetailsDataSetTemp["fooding_hash"][dateKey][ageRange].head_count);
                }
            }
            if (type == "description") {
                if (e.target.value != "") {
                    setDescriptionText(e.target.value)
                } else {
                    setDescriptionText("")
                }

            }

            if (type == "breakfastIncluded") {
                paymentDetailsDataSetTemp["fooding_hash"][dateKey][ageRange].is_breakfast_available = e.target.checked == true ? 1 : 0;
                if (Number(paymentDetailsDataSetTemp["fooding_hash"][dateKey][ageRange].breakfast_rate) == 0 && paymentDetailsDataSetTemp["fooding_hash"][dateKey][ageRange].hasOwnProperty('breakfast_rate_base') && paymentDetailsDataSetTemp["fooding_hash"][dateKey][ageRange].breakfast_rate_base) {
                    paymentDetailsDataSetTemp["fooding_hash"][dateKey][ageRange].breakfast_rate = paymentDetailsDataSetTemp["fooding_hash"][dateKey][ageRange].breakfast_rate_base;
                    paymentDetailsDataSetTemp["fooding_hash"][dateKey][ageRange].breakfast_total = Number(paymentDetailsDataSetTemp["fooding_hash"][dateKey][ageRange].breakfast_rate_base) * Number(paymentDetailsDataSetTemp["fooding_hash"][dateKey][ageRange].head_count);
                }
            }
            if (type == "lunchIncluded") {
                paymentDetailsDataSetTemp["fooding_hash"][dateKey][ageRange].is_lunch_available = e.target.checked == true ? 1 : 0;
                if (Number(paymentDetailsDataSetTemp["fooding_hash"][dateKey][ageRange].lunch_rate) == 0 && paymentDetailsDataSetTemp["fooding_hash"][dateKey][ageRange].hasOwnProperty('lunch_rate_base') && paymentDetailsDataSetTemp["fooding_hash"][dateKey][ageRange].lunch_rate_base) {
                    paymentDetailsDataSetTemp["fooding_hash"][dateKey][ageRange].lunch_rate = paymentDetailsDataSetTemp["fooding_hash"][dateKey][ageRange].lunch_rate_base;
                    paymentDetailsDataSetTemp["fooding_hash"][dateKey][ageRange].lunch_total = Number(paymentDetailsDataSetTemp["fooding_hash"][dateKey][ageRange].lunch_rate_base) * Number(paymentDetailsDataSetTemp["fooding_hash"][dateKey][ageRange].head_count);
                }
            }
            if (type == "dinnerIncluded") {
                paymentDetailsDataSetTemp["fooding_hash"][dateKey][ageRange].is_dinner_available = e.target.checked == true ? 1 : 0;
                if (Number(paymentDetailsDataSetTemp["fooding_hash"][dateKey][ageRange].dinner_rate) == 0 && paymentDetailsDataSetTemp["fooding_hash"][dateKey][ageRange].hasOwnProperty('dinner_rate_base') && paymentDetailsDataSetTemp["fooding_hash"][dateKey][ageRange].dinner_rate_base) {
                    paymentDetailsDataSetTemp["fooding_hash"][dateKey][ageRange].dinner_rate = paymentDetailsDataSetTemp["fooding_hash"][dateKey][ageRange].dinner_rate_base;
                    paymentDetailsDataSetTemp["fooding_hash"][dateKey][ageRange].dinner_total = Number(paymentDetailsDataSetTemp["fooding_hash"][dateKey][ageRange].dinner_rate_base) * Number(paymentDetailsDataSetTemp["fooding_hash"][dateKey][ageRange].head_count);
                }
            }

            setPaymentDetailsDataSet(paymentDetailsDataSetTemp)
        }
    }

    useEffect(() => {
    }, [paymentDetailsDataSet])


    const dateWisePrice = (data) => {
        let tooltipContent = [];

        Object.keys(data).map((date, idx) => {
            tooltipContent.push(
                <div className='tooltipContent' key={idx}>
                    <span>Date: {moment(date.toString()).format('DD-MM-YYYY')} </span>
                    <span>Rate: {Utility.getCurrencySymbol(currencyName)} {data[date]}</span>
                </div>
            );
        });
        return tooltipContent;
    }


    const dateWiseRateDetailsModalFN = (data, dataFor = "") => {
        setDateWiseRateDetailsModalFlag(true);
        setDateWiseRateDetailsData(data);
    };
    const closeDateWiseRateDetailsModalFN = () => {
        setDateWiseRateDetailsModalFlag(false);
        setDateWiseRateDetailsData({});
    }

    //guest data save formatting dataset function
    const formatingDataset = () => {
        let dataset = [];
        let hash = {}
        if (formData.name != "") {
            hash['first_name'] = (formData.name).trim();
        }
        if (formData.last_name != "") {
            hash['last_name'] = (formData.last_name).trim();
        }
        if (formData.email != "") {
            hash['email'] = formData.email;
        }

        if (formData.customer_gstin_no != "") {
            hash['customer_gstin_no'] = (formData.customer_gstin_no).trim();
        }
        if (formData.company_name != "") {
            hash['company_name'] = formData.company_name;
        }
        if (formData.company_address != "") {
            hash['company_address'] = formData.company_address;
        }

        var phoneNumber = formData.phone_number
        var plusRemovePhoneNumber = phoneNumber.replace(/\+/g, '')
        if (formData.phone_number != "") {
            hash['contact_number'] = plusRemovePhoneNumber;
        }

        hash['user_type'] = "customer";
        hash['is_active'] = true
        dataset.push(hash)
        return dataset;

    }

    //guest data save api function
    const gaustDataSave = () => {
        let promise = new Promise((resolve, reject) => {
            const { loaderStateTrue, loaderStateFalse } = props;
            let vaildData = validConfirmBookingModalFn();
            if (vaildData) {
                let data = formatingDataset();
                loaderStateTrue();
                guestCreate(data).then((response) => {
                    if (response.length > 0) {
                        response.map((dataObj) => {
                            if (dataObj.success) {
                                resolve(dataObj)
                            } else {
                                Utility.toastNotifications(dataObj.message, "Error", "error")
                                loaderStateFalse();
                            }
                        })
                    }
                    loaderStateFalse();
                }).catch((error) => {
                    loaderStateFalse();
                });
            }
        })
        return promise;
    }

    //booking dataset formatting functions
    const FormattingSendQuoatationDataFn = (type) => {
        // props.hotelRoomBookDataSet[0].date_wise_food_hash = paymentDetailsDataSet.fooding_hash
        // props.hotelRoomBookDataSet[0]["customer_id"] = props.userCredentials.id;
        // props.hotelRoomBookDataSet[0]["calculationType"] = type;
        // if (!props.isGuestFlag) {
        //     props.hotelRoomBookDataSet[0]["customer_name"] = props.userCredentials.user_details.first_name + " " + props.userCredentials.user_details.last_name;
        //     props.hotelRoomBookDataSet[0]["customer_phone"] = props.userCredentials.user_details.contact_number.includes("+") ? props.userCredentials.user_details.contact_number : "+" + props.userCredentials.user_details.contact_number;
        //     props.hotelRoomBookDataSet[0]["customer_email"] = props.userCredentials.user_details.user_email;
        // } else {
        //     props.hotelRoomBookDataSet[0]["customer_name"] = formData.name + " " + formData.last_name;
        //     props.hotelRoomBookDataSet[0]["customer_phone"] = formData.phone_number.includes("+") ? formData.phone_number : "+" + formData.phone_number;
        //     props.hotelRoomBookDataSet[0]["customer_email"] = formData.email[0].email;
        //     props.hotelRoomBookDataSet[0]["customer_gstin_no"] = formData.customer_gstin_no;
        //     props.hotelRoomBookDataSet[0]["company_name"] = formData.company_name;
        //     props.hotelRoomBookDataSet[0]["company_address"] = formData.company_address;
        // }
        // props.hotelRoomBookDataSet[0]["is_igst_applicable"] = paymentDetailsDataSet.hasOwnProperty('is_igst_enable') && paymentDetailsDataSet.is_igst_enable ? true : false;
        // props.hotelRoomBookDataSet[0]["extra_notations"] = payableServiceList;
        // return props.hotelRoomBookDataSet;
        let dataSet = {};
        if (props.hotelRoomBookDataSet?.length > 0) {
            props.hotelRoomBookDataSet.forEach((hotelData) => {
                let foodHash = {};
                if (props.callFrom === "edit_booking") {
                    console.log('edit_booking')
                    let previousFoodingObj = paymentDetailsDataSet?.previous_food_hash || {};
                    let foodingObj = paymentDetailsDataSet?.fooding_hash || {};
                    foodHash = { ...previousFoodingObj, ...foodingObj };
                } else {
                    console.log('add_booking')
                    foodHash = paymentDetailsDataSet?.fooding_hash || {};
                }
                console.log('foodHash', foodHash)
                hotelData["date_wise_food_hash"] = foodHash;

            });
            dataSet["is_igst_applicable"] = !!paymentDetailsDataSet?.is_igst_enable;
            dataSet["extra_notations"] = payableServiceList;
        }
        dataSet["booking_id"] = JSON.parse(JSON.stringify(props.hotelRoomBookDataSet))?.[0]?.booking_id
        dataSet["org_id"] = JSON.parse(JSON.stringify(props.hotelRoomBookDataSet))?.[0]?.org_id
        dataSet["branch_id"] = JSON.parse(JSON.stringify(props.hotelRoomBookDataSet))?.[0]?.branch_id
        dataSet['full_journey_details'] = JSON.parse(JSON.stringify(props.hotelRoomBookDataSet));
        dataSet['customer_id'] = props.userCredentials.id;
        dataSet["calculationType"] = type;
         if (!props.isGuestFlag) {
           dataSet["customer_name"] = props.userCredentials.user_details.first_name + " " + props.userCredentials.user_details.last_name;
           dataSet["customer_phone"] = props.userCredentials.user_details.contact_number.includes("+") ? props.userCredentials.user_details.contact_number : "+" + props.userCredentials.user_details.contact_number;
           dataSet["customer_email"] = props.userCredentials.user_details.user_email;
        } else {
           dataSet["customer_name"] = formData.name + " " + formData.last_name;
           dataSet["customer_phone"] = formData.phone_number.includes("+") ? formData.phone_number : "+" + formData.phone_number;
           dataSet["customer_email"] = formData.email[0].email;
           dataSet["customer_gstin_no"] = formData.customer_gstin_no;
           dataSet["company_name"] = formData.company_name;
           dataSet["company_address"] = formData.company_address;
        }
        return dataSet;
    }

    const generateDefaultEmailIds = (emailArray) => {
        const extractedEmails = emailArray.map(item => item.email);
        return extractedEmails;
    };

    const sendQuoatationFn = (type) => {
        const { loaderStateTrue, loaderStateFalse } = props;
        let valid = true
        if (valid) {
            let dataSet = FormattingSendQuoatationDataFn(type);
            loaderStateTrue();
            paymentPriceDistribution(dataSet).then((response) => {
                if (response.length > 0) {
                    if (response[0].success) {
                        let resetReduxData = {
                            destination: "",
                            start_date: null,
                            end_date: null,
                            adult: 1,
                            children: 0,
                            selectedRoom: {},
                            children_age: [],
                            filterData: {}
                        }
                        props.userSearchData(resetReduxData)
                        props.selectChooseBranch({})
                        props.priceDistributionRequestData([])
                        props.priceDistributionResponseData({})
                        props.closePaymentDetailsModalFn();
                        EventEmitter.emit("sendMessage", props.isGuestFlag)
                        history.push(`/${localStorage.getItem('i18nextLng')}/booking_history`);
                        Utility.toastNotifications(response[0].message, "Success", "success");

                    } else {
                        Utility.toastNotifications(response[0].message, "Error", "error");
                    }
                }
                loaderStateFalse();
            }).catch((error) => {
                console.error("************error*************", error)
                if (error) {
                }
                loaderStateFalse();
                if (error.message == "Network Error") {

                }
            });
        }
    }

    //cgst/sgst to igst onchange event
    const handleChangeIgstFn = (e, type) => {
        const { checked } = e.target;
        if (type === "cgst_sgst") {
            if (checked) {
                setPaymentDetailsDataSet((prevData) => ({
                    ...prevData,
                    is_igst_enable: false,
                }));
            }
        }
        if (type === "igst") {
            if (checked) {
                setPaymentDetailsDataSet((prevData) => ({
                    ...prevData,
                    is_igst_enable: true,
                }));
            }
        }
    };

    //if igst applicable then it set to igst enabled mode
    useEffect(() => {
        if (props.isIgstApplicable) {
            setPaymentDetailsDataSet((prevData) => ({
                ...prevData,
                is_igst_enable: true,
            }));
        }
    }, [props.isIgstApplicable])


    const roomTypeChangeFn = () => {
        setRoomBookCreateModalFlag(true);
    }

    const closeRoomBookCreateModal = () => {
        setRoomBookCreateModalFlag(false)
    }
    const closeRoomBookModalCallBackFn = () => {
        setRoomBookCreateModalFlag(false)
    }

    const descriptionModalFn = (dateKey, description, is_new) => {
        setDateKeyValue(dateKey)
        setDescriptionText(description)
        setDescriptionModalFlag(true)
        setIsNewBooking(is_new);
    }

    //close description modal function
    const closeDescriptionModal = () => {
        setDescriptionModalFlag(false)
        setDescriptionBoxStatus(true)
        setDateKeyValue("")
        setDescriptionText("")
    }

    const descriptionSaveFn = () => {
        let paymentDetailsDataSetTemp = { ...paymentDetailsDataSet }
        if (Object.keys(paymentDetailsDataSetTemp).length > 0) {
            paymentDetailsDataSetTemp["fooding_hash"][dateKeyValue].description = descriptionText;
            setPaymentDetailsDataSet(paymentDetailsDataSetTemp)
            closeDescriptionModal()
        }

    }

    useEffect(() => {
    }, [descriptionBoxStatus])

    const editDescriptionFn = (data) => {
        setDescriptionBoxStatus(data)
    }

    const addMoreEmail = () => {
        if (formData.email.length < 6) {
            setFormData((prevFormData) => {
                const newEmailObj = { 'email': '' };
                return {
                    ...prevFormData,
                    email: [...prevFormData.email, newEmailObj]
                };
            });

            setFormDataError((prevFormDataError) => {
                const newErrorObj = { 'email': '' };
                return {
                    ...prevFormDataError,
                    email: [...prevFormDataError.email, newErrorObj]
                };
            });
            if (emailListRef.current.length > 0) {
                emailListRef.current[emailListRef.current.length - 1].current.focus();
            }

        } else {
            Utility.toastNotifications('Cannot add more than 5 additional emails', "Warning", "warning");
        }

    }

    //email remove fn
    const emailRemoveClick = (e, idx) => {
        const updatedFormData = { ...formData };
        const updatedFormDataError = { ...formDataError };

        updatedFormData.email.splice(idx, 1);
        updatedFormDataError.email.splice(idx, 1);
        setFormData(updatedFormData);
        setFormDataError(updatedFormDataError);
    };

    //check email through api , it will associate with system or not
    const emailCheck = (e) => {
        let updatedFormDataError = { ...formDataError };
        let emailValidate = ValidateUtility.validate_email(e.target.value);
        if (emailValidate) {
            const { loaderStateTrue, loaderStateFalse } = props;
            loaderStateTrue();
            let data = { email: e.target.value }
            emailCheckApi(data).then((response) => {
                loaderStateFalse();
            }).catch((error) => {
                if (!error.response.data.success) {
                    updatedFormDataError['email'][0].email = error.response.data.message
                    setFormDataError(updatedFormDataError);
                }
                loaderStateFalse();
                if (error.message == "Network Error") {

                }
            });
        } else {
        }
    }

    //email input field ui
    const emailUi = () => {
        return formData.email.map((emailObj, idx) => (
            <>
                <div key={idx} className="col-md-3">
                    <div className="payableServiceRow">
                        <div className="row">
                            <div className="col-md-12 editIconBox">
                                <ErrorBoundary title="CustomInput Error">
                                    <CustomInput
                                        parentClassName="input_field_inner"
                                        labelName="Email"
                                        errorLabel={formDataError.email[idx].email}
                                        name={`email_${idx}`}
                                        type="text"
                                        value={emailObj.email}
                                        labelPresent={true}
                                        requiredStar={true}
                                        onChange={(e) => handelChangeEmailFn(e, 'email', idx)}
                                    />
                                </ErrorBoundary>
                                <div className="deleteBoxInner">
                                    {idx > 0 && (
                                        <span onClick={(e) => { emailRemoveClick(e, idx) }} className="deleteBox" >
                                            <i className="fa fa-trash" aria-hidden="true"></i>
                                        </span>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        ));
    };

    //when add more email then page will scroll
    useEffect(() => {
        if (formData.email.length > 1) {
            if (emailInputRef.current) {
                emailInputRef.current.scrollIntoView({ behavior: 'smooth' });
            }
        }
    }, [formData]);

    //email input onchange fn
    const handelChangeEmailFn = (e, field, idx) => {
        const value = e.target.value;
        const updatedFormData = { ...formData };
        const updatedFormDataError = { ...formDataError };
        if (field === 'email' && typeof idx === 'number') {
            let emailValidate = ValidateUtility.validate_email(value);
            if (emailValidate) {
                updatedFormData[field][idx].email = value;
                updatedFormDataError[field][idx].email = "";
            } else {
                updatedFormData[field][idx].email = value;
                updatedFormDataError[field][idx].email = "Enter valid email"
            }
        } else {
            updatedFormData[field] = value;
        }
        setFormData(updatedFormData);
    };

    const addMorePayableService = () => {
        setPayableServiceList((prevList) => [
            ...prevList, { "description": "", 'amount': '' }
        ]);
    };

    const payableServiceRemoveClick = (e, idx, val) => {
        let payableServiceListTemp = payableServiceList.slice();
        payableServiceListTemp.splice(idx, 1);
        setPayableServiceList(payableServiceListTemp);
    }

    const handleChangeServiceFn = (e, service, idx, type) => {
        let updatedPayableServiceList = [...payableServiceList];
        if (type == 'description') {
            if (e.target.value != "") {
                updatedPayableServiceList[idx] = {
                    ...updatedPayableServiceList[idx],
                    description: e.target.value
                };

            } else {
                updatedPayableServiceList[idx] = {
                    ...updatedPayableServiceList[idx],
                    description: ""
                };
            }
        }
        if (type == 'amount') {
            var expr = /^\d{0,8}(\.\d{0,2})?$/;
            if (!expr.test(e.target.value)) {

            } else {
                if (e.target.value != "") {
                    updatedPayableServiceList[idx] = {
                        ...updatedPayableServiceList[idx],
                        amount: e.target.value
                    };
                } else {
                    updatedPayableServiceList[idx] = {
                        ...updatedPayableServiceList[idx],
                        amount: e.target.value
                    };
                }
            }
        }

        setPayableServiceList(updatedPayableServiceList);
    };

    const calculate_additionalServiceAmount = () => {
        return payableServiceList.reduce((totalAmount, service) => totalAmount + Number(service.amount), 0);
    }

    //calculate minimum payment amount of a booking
    const calculateMinimumPayableAmount = () => {
        let minimumPayPercent = paymentDetailsDataSet.hasOwnProperty('minimum_percent') && paymentDetailsDataSet.minimum_percent
            ? paymentDetailsDataSet.minimum_percent
            : 0;
        let fullBookingAmount = Number(calculate_roomrate_amount().totalPayableRoundedAmount);
        let payableAmount = ((fullBookingAmount * Number(minimumPayPercent)) / 100).toFixed(2);
        return payableAmount;
    }
    const resetDataGridFnFromChild = () => { }

    //close payment modal callback fn
    const closePaymentModeModalFnChild = () => {
        closePaymentModalFn();
    }

    //close price distribution modal callback fn
    const closePayModalSuccessFn = () => {
        let resetReduxData = {
            destination: "",
            start_date: new Date(),
            end_date: new Date(new Date().getTime() + 24 * 60 * 60 * 1000),
            adult: 1,
            children: 0,
            selectedRoom: {},
            children_age: [],
            filterData: {}
        }
        props.userSearchData(resetReduxData)
        props.selectChooseBranch({})
        props.priceDistributionRequestData([])
        props.priceDistributionResponseData({})
        props.closePaymentDetailsModalFn();
        EventEmitter.emit("sendMessage", props.isGuestFlag)
        history.push(`/${localStorage.getItem('i18nextLng')}/booking_history`);
    }

    //Confirm This Booking pop up modal cancel button fn
    const confirmbookingCancellationCancleButton = () => {
        setBookingCancellationModalFlag(false);
    }

    //verification code modal close fn
    const handleClose = () => {
        setForcePasswordChange(false);
        setPassword("")
        setRetypePassword("")
        setveryfiCode("")
        setveryfiFlag(false);
        setupdated_passwordError("");
        setupdated_comfirm_passwordError("");
        setCreateNewPassword("");

        setState(prev => ({
            ...prev,
            changePasswordLock: false,
            changePasstype: "password",
            changeConpasstype: "password",
            changeConpasswordLock: false
        }));
    }

    //verification code onchange function
    const handleChangeUpdatePassword = (e, type) => {
        if (type == 'paassword') {
            setPassword(e.target.value)
            setupdated_passwordError("")
        }
        if (type == 'retype_paassword') {
            setRetypePassword(e.target.value)
            setupdated_comfirm_passwordError("")
        }
        if (type == 'code') {
            setveryfiCode(e.target.value)
            setcodeError("")
        }
    }

    //password check function
    const updatePasswordCheck = () => {
        let valid = true;
        if (password != "" && retypePassword != "") {
            if (password == retypePassword && password != "" && retypePassword != "") {
                setupdated_passwordError("")
                setupdated_comfirm_passwordError("")
            } else {
                valid = false;
                Utility.toastNotifications("Password and confirm password does not match", "Error", "error");
            }
        }
        return valid;
    }

    //password validation fn
    const validPasswordData = () => {
        let valid = true;
        if (password.trim() === "") {
            valid = false;
            setupdated_passwordError("Please enter password")
        } else if (password.length < 6) {
            valid = false;
            setupdated_passwordError("Password must be at least 6 characters.")

        } else {
            setupdated_passwordError("")
        }
        if (retypePassword.trim() === "") {
            valid = false;
            setupdated_comfirm_passwordError("Please enter retype password")
        } else if (retypePassword.length < 6) {
            valid = false;
            setupdated_comfirm_passwordError("Password must be at least 6 characters.")

        } else {
            setupdated_comfirm_passwordError("")
        }

        //verification code
        if (veryfiFlag) {
            if (veryfiCode.trim() === "") {
                valid = false;
                setcodeError("Please enter verification code")
            } else if (veryfiCode.length < 6) {
                valid = false;
                setcodeError("Verification code must be at least 6 characters.")
            } else {
                setcodeError("")
            }
        }
        return valid;
    }

    //verication code submit with signup customer post api
    const updatePassword = (validPasswordValue) => {
        let validPassword = validPasswordData();
        let validPasswordstrong = updatePasswordCheck();
        const { loaderStateTrue, loaderStateFalse } = props;
        loaderStateTrue();
        let data = {};
        if (!veryfiFlag) {
            data = {
                "username": formData.email[0].email,
                "password": validPasswordValue,
                "first_name": formData.name,
                "last_name": formData.last_name,
                "contact_number": formData.phone_number
            }
        }
        if (veryfiFlag) {
            data = {
                "email": formData.email[0].email,
                "password": validPasswordValue,
                "confirmation_code": veryfiCode
            }
        }
        signupCustomer(data, veryfiFlag).then((response) => {
            if (response.success) {
                if (!veryfiFlag) {
                    setveryfiFlag(true);
                    setForcePasswordChange(true);
                } else {
                    setAlertContainer(true);
                    loginSuccess(response);
                }
            }
            loaderStateFalse();
        }).catch((error) => {
            loaderStateFalse();

        });
    }

    //Your login credentials has been sent to your email. -- popup hide fn
    const alertContainerHide = () => {
        setAlertContainer(false);
        setPaymentModalFlag(true);
    }


    //after signup completes user will automatically login
    const loginSuccess = (response) => {
        const { loaderStateTrue, loaderStateFalse, setToken, setUserCredentials } = props;
        const finalIdToken = response.data.tokenType + ' ' + response.data.idToken;
        const accessToken = response.data.accessToken
        const refreshToken = response.data.refreshToken
        const expiresIn = LoginUtility.getExpiryDetails(response.data.expiresIn)
        setToken(finalIdToken, accessToken, expiresIn, refreshToken).then(() => {
            loaderStateTrue();
            let isVerifyData = { is_verify: true }
            LoginController.getCurrentUser(isVerifyData).then((userResponse) => {
                loaderStateFalse();
                if (userResponse.success) {
                    Utility.toastNotificationsDismissAll();
                    setUserCredentials(userResponse.data)
                    handleClose();
                    confirmbookingConfirmButton("NewRegister");
                } else {
                    Utility.toastNotifications(userResponse.message, "Error", "error")
                }
            })
        })
    }

    const changeShowText = () => {
        setState(prev => ({
            ...prev,
            changePasswordLock: true,
            changePasstype: "text"
        }))
    }

    const changeShowPassword = () => {
        setState(prev => ({
            ...prev,
            changePasswordLock: false,
            changePasstype: "password"
        }))
    }
    const changeConShowText = () => {
        setState(prev => ({
            ...prev,
            changeConpasswordLock: true,
            changeConpasstype: "text"
        }))
    }

    const changeConShowPassword = () => {
        setState(prev => ({
            ...prev,
            changeConpasswordLock: false,
            changeConpasstype: "password"
        }))
    }

    return (
        <div className="gridcontainer">
            <div className="modalinnerbody">
                <div className="innerbodydimension relativeSection">
                    <div className="col-md-12 viewForMobile">
                        <div className='topBtnSection'>
                            <div className="row footerSecForPaymentModal">
                                <div className="col-md-12 text-center topspace">
                                    {props.createBookingFlag ?
                                        <>
                                            <button type="button" className="modbtn" onClick={() => paymentAndBookingFn()}>{props.callFrom == "edit_booking" ? "Update" : "Confirm Booking"}</button>
                                        </> : null
                                    }
                                </div>
                            </div>
                        </div>
                        <div className='paymentDetailsSectionBoxScrollBox'>
                            <div className='paymentDetailsSectionBox onlyDesktop'>
                                {paymentPriceDistributionUi()}
                                {paymentDetailsDataSet.hasOwnProperty('room_type_data') && paymentDetailsDataSet.room_type_data && Object.keys(paymentDetailsDataSet.room_type_data).length > 0 &&
                                    <>
                                        <div className='singleRowBox'>
                                            <div className='singleRowBoxInnerLeft boldText'>{Utility.capitalizeFirstLetterOfEachWord('Total')}:</div>

                                            <div className='singleRowBoxInnerRight boldText'>{Utility.getCurrencySymbol(currencyName)} {Number(calculate_roomrate_amount().roomRateAmountwithoutgst).toFixed(2)}</div>
                                        </div>
                                        {paymentDetailsDataSet.hasOwnProperty('is_igst_enable') && !paymentDetailsDataSet.is_igst_enable ?
                                            <>
                                                {Number(calculate_roomrate_amount().cgstPercentage) !== 0 && <div className='singleRowBox'>
                                                    <div className='singleRowBoxInnerLeft'>CGST @ {Number(calculate_roomrate_amount().cgstPercentage).toFixed(2)}% :</div>
                                                    <div className='singleRowBoxInnerRight'>{Utility.getCurrencySymbol(currencyName)} {Number(calculate_roomrate_amount().cgstAmount).toFixed(2)}</div>
                                                </div>}
                                                {Number(calculate_roomrate_amount().sgstPercentage) !== 0 && <div className='singleRowBox'>
                                                    <div className='singleRowBoxInnerLeft'>SGST @ {Number(calculate_roomrate_amount().sgstPercentage).toFixed(2)}% :</div>
                                                    <div className='singleRowBoxInnerRight'>{Utility.getCurrencySymbol(currencyName)} {Number(calculate_roomrate_amount().sgstAmount).toFixed(2)}</div>
                                                </div>}
                                            </> :
                                            <>
                                                {Number(calculate_roomrate_amount().igstPercentage) !== 0 && <div className='singleRowBox'>
                                                    <div className='singleRowBoxInnerLeft'>IGST @ {Number(calculate_roomrate_amount().igstPercentage).toFixed(2)}% :</div>
                                                    <div className='singleRowBoxInnerRight'>{Utility.getCurrencySymbol(currencyName)} {Number(calculate_roomrate_amount().igstAmount).toFixed(2)}</div>
                                                </div>}
                                            </>
                                        }
                                        <div className='singleRowBox'>
                                            <div className='singleRowBoxInnerLeft boldText'>{Utility.capitalizeFirstLetterOfEachWord('Total payable amount (Room)')}:</div>
                                            <div className='singleRowBoxInnerRight boldText'>{Utility.getCurrencySymbol(currencyName)} {Number(calculate_roomrate_amount().totalPayableAmount).toFixed(2)}</div>
                                        </div>
                                    </>}
                                {foodPriceDistributionUi()}
                                {payableServiceList.length > 0 ? <>
                                    <div className='singleRowBox'>
                                        <div className='singleRowBoxInnerLeft boldText'>{Utility.capitalizeFirstLetterOfEachWord('Total')}:</div>
                                        <div className='singleRowBoxInnerRight text-right boldText'>{Utility.getCurrencySymbol(currencyName)} {Number(calculate_additionalServiceAmount()).toFixed(2)}</div>
                                    </div>
                                    {paymentDetailsDataSet.hasOwnProperty('is_igst_enable') && !paymentDetailsDataSet.is_igst_enable ?
                                        <>
                                            {Number(calculate_roomrate_amount().notation_cgst) !== 0 && <div className='singleRowBox'>
                                                <div className='singleRowBoxInnerLeft'>CGST @ {Number(calculate_roomrate_amount().notation_cgst).toFixed(2)}% :</div>
                                                <div className='singleRowBoxInnerRight'>{Utility.getCurrencySymbol(currencyName)} {Number(calculate_roomrate_amount().notation_cgst_amount).toFixed(2)}</div>
                                            </div>}
                                            {Number(calculate_roomrate_amount().notation_sgst) !== 0 && <div className='singleRowBox'>
                                                <div className='singleRowBoxInnerLeft'>SGST @ {Number(calculate_roomrate_amount().notation_sgst).toFixed(2)}% :</div>
                                                <div className='singleRowBoxInnerRight'>{Utility.getCurrencySymbol(currencyName)} {Number(calculate_roomrate_amount().notation_sgst_amount).toFixed(2)}</div>
                                            </div>}
                                        </> :
                                        <>
                                            {Number(calculate_roomrate_amount().notation_igst) !== 0 && <div className='singleRowBox'>
                                                <div className='singleRowBoxInnerLeft'>IGST @ {Number(calculate_roomrate_amount().notation_igst).toFixed(2)}% :</div>
                                                <div className='singleRowBoxInnerRight'>{Utility.getCurrencySymbol(currencyName)} {Number(calculate_roomrate_amount().notation_gst_amount).toFixed(2)}</div>
                                            </div>}
                                        </>
                                    }
                                    <div className='singleRowBox'>
                                        <div className='singleRowBoxInnerLeft boldText'>{Utility.capitalizeFirstLetterOfEachWord('Total Payable Amount Service Notes')}:</div>
                                        <div className='singleRowBoxInnerRight boldText'>{Utility.getCurrencySymbol(currencyName)} {Number(calculate_roomrate_amount().notationamount).toFixed(2)}</div>
                                    </div>
                                </> : null}
                                {/* new added filed start*/}
                                <div className='singleRowBox totalAmount'>
                                    <div className='singleRowBoxInnerLeft boldText'>{Utility.capitalizeFirstLetterOfEachWord('Total amount')}:</div>
                                    <div className='singleRowBoxInnerRight boldText'>{Utility.getCurrencySymbol(currencyName)} {(Number(calculate_roomrate_amount().fullbookingamount)).toFixed(2)} </div>
                                </div>
                                <div className='singleRowBox totalAmount'>
                                    <div className='singleRowBoxInnerLeft boldTextAmount'>{Utility.capitalizeFirstLetterOfEachWord('Round of Amount')}:</div>
                                    <div className='singleRowBoxInnerRight boldTextAmount'>{Utility.getCurrencySymbol(currencyName)} {(Number(calculate_roomrate_amount().roundOffAmount)).toFixed(2)}</div>
                                </div>
                                <div className='singleRowBox totalAmount'>
                                    <div className='singleRowBoxInnerLeft boldTextAmount'>{Utility.capitalizeFirstLetterOfEachWord('Payable amount')}:</div>
                                    <div className='singleRowBoxInnerRight boldTextAmount'>{Utility.getCurrencySymbol(currencyName)} {(Number(calculate_roomrate_amount().totalPayableRoundedAmount)).toFixed(2)}</div>
                                </div>
                            </div>
                            <div className='paymentDetailsSectionBox onlyMobile'>
                                <Accordion defaultActiveKey="0">
                                    <Accordion.Item eventKey="0">
                                        <Accordion.Header>Room Details</Accordion.Header>
                                        <Accordion.Body>
                                            <div className='accordion-body-Box'>
                                                {paymentPriceDistributionUi()}
                                                {paymentDetailsDataSet.hasOwnProperty('room_type_data') && paymentDetailsDataSet.room_type_data && Object.keys(paymentDetailsDataSet.room_type_data).length > 0 &&
                                                    <>
                                                        <div className='singleRowBox'>
                                                            <div className='singleRowBoxInnerLeft boldText'>{Utility.capitalizeFirstLetterOfEachWord('Total')}:</div>

                                                            <div className='singleRowBoxInnerRight boldText'>{Utility.getCurrencySymbol(currencyName)} {Number(calculate_roomrate_amount().roomRateAmountwithoutgst).toFixed(2)}</div>
                                                        </div>
                                                        {paymentDetailsDataSet.hasOwnProperty('is_igst_enable') && !paymentDetailsDataSet.is_igst_enable ?
                                                            <>
                                                                {Number(calculate_roomrate_amount().cgstPercentage) !== 0 && <div className='singleRowBox'>
                                                                    <div className='singleRowBoxInnerLeft'>CGST @ {Number(calculate_roomrate_amount().cgstPercentage).toFixed(2)}% :</div>
                                                                    <div className='singleRowBoxInnerRight'>{Utility.getCurrencySymbol(currencyName)} {Number(calculate_roomrate_amount().cgstAmount).toFixed(2)}</div>
                                                                </div>}
                                                                {Number(calculate_roomrate_amount().sgstPercentage) !== 0 && <div className='singleRowBox'>
                                                                    <div className='singleRowBoxInnerLeft'>SGST @ {Number(calculate_roomrate_amount().sgstPercentage).toFixed(2)}% :</div>
                                                                    <div className='singleRowBoxInnerRight'>{Utility.getCurrencySymbol(currencyName)} {Number(calculate_roomrate_amount().sgstAmount).toFixed(2)}</div>
                                                                </div>}
                                                            </> :
                                                            <>
                                                                {Number(calculate_roomrate_amount().igstPercentage) !== 0 && <div className='singleRowBox'>
                                                                    <div className='singleRowBoxInnerLeft'>IGST @ {Number(calculate_roomrate_amount().igstPercentage).toFixed(2)}% :</div>
                                                                    <div className='singleRowBoxInnerRight'>{Utility.getCurrencySymbol(currencyName)} {Number(calculate_roomrate_amount().igstAmount).toFixed(2)}</div>
                                                                </div>}
                                                            </>
                                                        }
                                                        <div className='singleRowBox'>
                                                            <div className='singleRowBoxInnerLeft boldText'>{Utility.capitalizeFirstLetterOfEachWord('Total payable amount (Room)')}:</div>
                                                            <div className='singleRowBoxInnerRight boldText'>{Utility.getCurrencySymbol(currencyName)} {Number(calculate_roomrate_amount().totalPayableAmount).toFixed(2)}</div>
                                                        </div>
                                                    </>}
                                            </div>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                    {((paymentDetailsDataSet.hasOwnProperty('fooding_hash') && paymentDetailsDataSet.fooding_hash && Object.keys(paymentDetailsDataSet.fooding_hash).length > 0) || (paymentDetailsDataSet.hasOwnProperty('previous_food_hash') && paymentDetailsDataSet.previous_food_hash && Object.keys(paymentDetailsDataSet.previous_food_hash).length > 0)) && <Accordion.Item eventKey="1">
                                        <Accordion.Header>Food Details</Accordion.Header>
                                        <Accordion.Body>
                                            <div className='accordion-body-Box'>
                                                {foodPriceDistributionUi()}
                                                {payableServiceList.length > 0 ? <>
                                                    <div className='singleRowBox'>
                                                        <div className='singleRowBoxInnerLeft boldText'>{Utility.capitalizeFirstLetterOfEachWord('Total')}:</div>
                                                        <div className='singleRowBoxInnerRight text-right boldText'>{Utility.getCurrencySymbol(currencyName)} {Number(calculate_additionalServiceAmount()).toFixed(2)}</div>
                                                    </div>
                                                    {paymentDetailsDataSet.hasOwnProperty('is_igst_enable') && !paymentDetailsDataSet.is_igst_enable ?
                                                        <>
                                                            {Number(calculate_roomrate_amount().notation_cgst) !== 0 && <div className='singleRowBox'>
                                                                <div className='singleRowBoxInnerLeft'>CGST @ {Number(calculate_roomrate_amount().notation_cgst).toFixed(2)}% :</div>
                                                                <div className='singleRowBoxInnerRight'>{Utility.getCurrencySymbol(currencyName)} {Number(calculate_roomrate_amount().notation_cgst_amount).toFixed(2)}</div>
                                                            </div>}
                                                            {Number(calculate_roomrate_amount().notation_sgst) !== 0 && <div className='singleRowBox'>
                                                                <div className='singleRowBoxInnerLeft'>SGST @ {Number(calculate_roomrate_amount().notation_sgst).toFixed(2)}% :</div>
                                                                <div className='singleRowBoxInnerRight'>{Utility.getCurrencySymbol(currencyName)} {Number(calculate_roomrate_amount().notation_sgst_amount).toFixed(2)}</div>
                                                            </div>}
                                                        </> :
                                                        <>
                                                            {Number(calculate_roomrate_amount().notation_igst) !== 0 && <div className='singleRowBox'>
                                                                <div className='singleRowBoxInnerLeft'>IGST @ {Number(calculate_roomrate_amount().notation_igst).toFixed(2)}% :</div>
                                                                <div className='singleRowBoxInnerRight'>{Utility.getCurrencySymbol(currencyName)} {Number(calculate_roomrate_amount().notation_gst_amount).toFixed(2)}</div>
                                                            </div>}
                                                        </>
                                                    }
                                                    <div className='singleRowBox'>
                                                        <div className='singleRowBoxInnerLeft boldText'>{Utility.capitalizeFirstLetterOfEachWord('Total Payable Amount Service Notes')}:</div>
                                                        <div className='singleRowBoxInnerRight boldText'>{Utility.getCurrencySymbol(currencyName)} {Number(calculate_roomrate_amount().notationamount).toFixed(2)}</div>
                                                    </div>
                                                </> : null}
                                            </div>
                                        </Accordion.Body>
                                    </Accordion.Item>}
                                </Accordion>
                                <div className='singleRowBox totalAmount'>
                                    <div className='singleRowBoxInnerLeft boldText'>{Utility.capitalizeFirstLetterOfEachWord('Total amount')}:</div>
                                    <div className='singleRowBoxInnerRight boldText'>{Utility.getCurrencySymbol(currencyName)} {(Number(calculate_roomrate_amount().fullbookingamount)).toFixed(2)} </div>
                                </div>
                                <div className='singleRowBox totalAmount'>
                                    <div className='singleRowBoxInnerLeft boldTextAmount'>{Utility.capitalizeFirstLetterOfEachWord('Round of Amount')}:</div>
                                    <div className='singleRowBoxInnerRight boldTextAmount'>{Utility.getCurrencySymbol(currencyName)} {(Number(calculate_roomrate_amount().roundOffAmount)).toFixed(2)}</div>
                                </div>
                                <div className='singleRowBox totalAmount'>
                                    <div className='singleRowBoxInnerLeft boldTextAmount'>{Utility.capitalizeFirstLetterOfEachWord('Payable amount')}:</div>
                                    <div className='singleRowBoxInnerRight boldTextAmount'>{Utility.getCurrencySymbol(currencyName)} {(Number(calculate_roomrate_amount().totalPayableRoundedAmount)).toFixed(2)}</div>
                                </div>
                            </div>
                            {props.isGuestFlag && <hr />}
                            {props.isGuestFlag && <div className='customerSecBox'>
                                {props.isGuestFlag && <div className='paymentCustomerHotelPart relativeRow' id='page-bottom'>
                                    <div className='row'>
                                        <div className="col-md-3">
                                            <ErrorBoundary title="CustomInput Error">
                                                <CustomInput
                                                    parentClassName="input_field_inner"
                                                    labelName="First name"
                                                    errorLabel={formDataError.name}
                                                    name="branch_name"
                                                    type="text"
                                                    value={formData.name}
                                                    labelPresent={true}
                                                    onChange={(e) => handelChangeCustomerFn(e, 'name')}
                                                />
                                            </ErrorBoundary>
                                        </div>
                                        <div className="col-md-3">
                                            <ErrorBoundary title="CustomInput Error">
                                                <CustomInput
                                                    parentClassName="input_field_inner"
                                                    labelName="Last name"
                                                    errorLabel={formDataError.last_name}
                                                    name="last_name"
                                                    type="text"
                                                    value={formData.last_name}
                                                    labelPresent={true}
                                                    onChange={(e) => handelChangeCustomerFn(e, 'last_name')}
                                                />
                                            </ErrorBoundary>
                                        </div>
                                        <div className="col-md-3">
                                            <ErrorBoundary title="CustomInput Error">
                                                <PhoneNumberWithCountryCode
                                                    title="Phone Number"
                                                    country={selectedCountryCode}
                                                    value={formData.phone_number}
                                                    onChange={(e, country) => handelChangeCustomerFn(e, 'phone_number', country)}
                                                    error={formDataError.phone_number}
                                                    labelShow={true}
                                                    requirStart={true}
                                                    disableDropdown={false}
                                                />
                                            </ErrorBoundary>
                                        </div>

                                        <div className="col-md-3">
                                            <ErrorBoundary title="CustomInput Error">
                                                <CustomInput
                                                    parentClassName="input_field_inner"
                                                    labelName="GSTIN Number"
                                                    errorLabel={formDataError.customer_gstin_no}
                                                    name="customer_gstin_no"
                                                    type="text"
                                                    value={formData.customer_gstin_no}
                                                    labelPresent={true}
                                                    onChange={(e) => handelChangeCustomerFn(e, 'customer_gstin_no')}
                                                    requiredStar={false}
                                                />
                                            </ErrorBoundary>
                                        </div>

                                        <div className="col-md-3">
                                            <ErrorBoundary title="CustomInput Error">
                                                <CustomInput
                                                    parentClassName="input_field_inner"
                                                    labelName="Company Name"
                                                    errorLabel={formDataError.company_name}
                                                    name="company_name"
                                                    type="text"
                                                    value={formData.company_name}
                                                    labelPresent={true}
                                                    onChange={(e) => handelChangeCustomerFn(e, 'company_name')}
                                                    requiredStar={false}
                                                />
                                            </ErrorBoundary>
                                        </div>
                                        <div className="col-md-3">
                                            <ErrorBoundary title="CustomInput Error">
                                                <CustomInput
                                                    parentClassName="input_field_inner"
                                                    labelName="Company Address"
                                                    errorLabel={formDataError.company_address}
                                                    name="company_address"
                                                    type="text"
                                                    value={formData.company_address}
                                                    labelPresent={true}
                                                    onChange={(e) => handelChangeCustomerFn(e, 'company_address')}
                                                    requiredStar={false}
                                                />
                                            </ErrorBoundary>
                                        </div>
                                        {emailUi()}
                                        <div ref={emailInputRef}></div>
                                    </div>
                                </div>}
                            </div>}

                        </div>
                    </div>

                </div>
            </div>


            <ErrorBoundary title="ModalGlobal Error">
                <ModalGlobal
                    show={paymentModalFlag}
                    onHide={closePaymentModalFn}
                    title="Payment"
                    className="modalcustomize mondimension paymentModalContent paymentModalDialogContent paymentModeModalContent paymentModeModalSection"
                    footer={false}
                    closeButton={true}
                    saveButtonLabel="Payment"
                    saveButtonClassName="btn btn-primary savebtn modbtn"
                    cancelShow={true}
                    cancelButtonLabel={"Cancel"}
                    cancelButtonClassName="modbtn cancelbtn"
                    footerClassName="text-center topspace"
                    body={
                        <>
                            <ErrorBoundary title="BranchAddEditModalContent Error">
                                <PaymentModeModalContent
                                    resetDataGridFnFromChild={resetDataGridFnFromChild}
                                    closePaymentModeModalFnChild={closePaymentModeModalFnChild}
                                    dueAmount={(Number(calculate_roomrate_amount().totalPayableRoundedAmount)).toFixed(2)}
                                    minimumAmount={calculateMinimumPayableAmount()}
                                    bookingDetailsDatasetWithoutPayment={bookingDetailsDatasetWithoutPayment}
                                    callFrom={'booking'}
                                    closePayModalSuccessFn={closePayModalSuccessFn}
                                    isFirstPayment={true}
                                    currency={currencyName}
                                />
                            </ErrorBoundary>
                        </>
                    }
                />
            </ErrorBoundary>

            <ErrorBoundary title="ModalGlobal Error">
                <ModalGlobal
                    show={bookingCancellationModalFlag}
                    onHide={cancellationModalHide}
                    className="modalcustomize confirmationalertmodal"
                    bodyClassName="cancelConfirmationbody"
                    headerclassName="close_btn_icon"
                    title="Confirm This Booking ?"
                    footer={false}
                    body={
                        <>
                            <ErrorBoundary title="ConfirmationAlert Error">
                                <ConfirmationAlert
                                    BodyFirstContent="Would you like to proceed with this booking?"
                                    BodyThirdContent=""
                                    confirmationButtonContent="Confirm"
                                    cancelButtonContent="Cancel"
                                    deleteConfirmButton={confirmbookingConfirmButton}
                                    deleteCancleButton={confirmbookingCancellationCancleButton}
                                    deleteIconVissable={false}
                                />
                            </ErrorBoundary>
                        </>
                    }
                />
            </ErrorBoundary>


            <Modal
                show={forcePasswordChange}
                onHide={handleClose}
                className={!veryfiFlag ? "forcePasswordChange changePasswordModalContent createPassword" : "forcePasswordChange changePasswordModalContent createPassword verificationModal"}
            >
                <Modal.Header closeButton>
                    <Modal.Title>{!veryfiFlag ? "Create Password" : "Verification Code"}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='password-rules-box'>
                        <div className={!veryfiFlag ? "pass-left-box" : "pass-left-box full-width"}>
                            <div className="passwordRow relativeRow">
                                <label className='inputLabel'>Please enter the verification code sent to your email to validate your booking</label>
                                <input type="text" onChange={(e) => handleChangeUpdatePassword(e, 'code')} name="code" placeholder='Please enter verification code' className="input__fields_property" value={veryfiCode} />

                                <div className="col-md-12 errorClass error_div">{codeError}</div>
                            </div>
                        </div>

                    </div>
                </Modal.Body>

                <Modal.Footer>
                    <Button variant="primary" onClick={updatePasswordFn} className="update_btn">Submit</Button>
                </Modal.Footer>
            </Modal>

            <ErrorBoundary title="ModalGlobal Error">
                <ModalGlobal
                    show={alertContainer}
                    onHide={alertContainerHide}
                    closeButton={true}
                    className="modalcustomize confirmationalertmodal alertContainer"
                    bodyClassName="cancelConfirmationbody"
                    headerclassName="close_btn_icon"
                    title="Note"
                    footer={false}
                    body={
                        <>
                            <ErrorBoundary title="ConfirmationAlert Error">
                                <ConfirmationAlert
                                    BodyThirdContent="Your login credentials has been sent to your email."
                                    deleteIconVissable={false}
                                />
                            </ErrorBoundary>
                        </>
                    }
                />
            </ErrorBoundary>
        </div >
    );
}


PaymentDetailsModalContent.defaultProps = {
    customerSelection: false,
    customerData: {},
    totalPaidAmount: '0',
    childrenAges: [],
    hotelRoomBookDataSet: [],
    callFrom: "",
    formData: {},
    payableNoteList: [],
    isIgstApplicable: false,
    customerIdProp: '',
    createBookingFlag: true
}
const mapStateToProps = (globalState) => {
    return {
        userCredentials: globalState.LoginReducer.userCredentials,
        token: globalState.LoginReducer.token,
        selected_branch: globalState.mainReducerData.selected_branch,
        priceDistributionRequestData: globalState.mainReducerData.priceDistributionRequestData,
        priceDistributionResponseData: globalState.mainReducerData.priceDistributionResponseData,
        userSearchDataFromReducer: globalState.mainReducerData.userSearchData,
    };
}

export default connect(mapStateToProps, { loaderStateTrue, loaderStateFalse, userSearchData, selectChooseBranch, priceDistributionRequestData, priceDistributionResponseData, setToken, setUserCredentials })(PaymentDetailsModalContent);
