import React, { Component } from 'react';
import '../Public/css/footer.scss';
import '../Public/css/footerresponsivedoc.scss';
import { Container } from 'react-bootstrap';
import { useParams, useHistory } from 'react-router-dom';

function Footer(props) {

    const history = useHistory();
    const { type } = useParams();
    const currentYear = new Date().getFullYear();
    const aboutUs = () => {
        history.push(`/${localStorage.getItem('i18nextLng')}/about_us`);
    }
    const termsConditions = () => {
        history.push(`/${localStorage.getItem('i18nextLng')}/terms_Conditions`);
    }
    const faq = () => {
        history.push(`/${localStorage.getItem('i18nextLng')}/faq`);
    }
    const PrivacyPolicy = () => {
        history.push(`/${localStorage.getItem('i18nextLng')}/privacy_policy`);
    }
    const Sitemap = () => {
        history.push(`/${localStorage.getItem('i18nextLng')}/sitemap`);
    }


    return (
        <>
            <div className="footerSec">
                <div className="footerSecTop">
                    <Container>
                        <div className="footerInn">
                            <div className="row">
                                <div className="col-xl-4 col-md-12 col-sm-12">
                                    <div className="footerBox">
                                        <a href="#" className="onlyMob"><img src={require('../Public/images/stayingLogo.png')} className="footerLogo" /></a>
                                        <p>Users can search for properties based on criteria such as location, check-in/check-out dates, number of guests, and room preferences. <a href="#" className="onlyDesktop"><img src={require('../Public/images/stayingLogo.png')} className="footerLogo" /></a> Detailed information about each property, including room types, amenities, photos, and reviews.</p>
                                        <p>A mobile-responsive design that ensures the site is accessible and user-friendly on smartphones and tablets.</p>
                                    </div>
                                </div>
                                <div className="col-xl-2 col-md-4 col-sm-6 col-6">
                                    <div className="footerBox">
                                        <ul>
                                            <li><a href="/">Home</a></li>
                                            <li><a><button onClick={() => { aboutUs() }}>About Us</button></a></li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-xl-2 col-md-4 col-sm-6 col-6">
                                    <div className="footerBox">
                                        <ul>
                                            <li><a><button onClick={() => { faq() }}>FAQ</button></a></li>
                                            <li><a><button onClick={() => { PrivacyPolicy() }}>Privacy Policy</button></a></li>
                                            <li><a><button onClick={() => { termsConditions() }}>Terms &amp; Conditions</button></a></li>
                                            <li><a><button onClick={() => { Sitemap() }}>Sitemap</button></a></li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-xl-4 col-md-4 col-sm-12">
                                    <div className="footerBox footInfo">
                                        <ul>
                                            <li><strong>Location -</strong>
                                                S-129, B.P. Township,<br />
                                                Patuli, Kolkata-700094</li>
                                            <li><strong>Contact -</strong><a href="tel:3340705047">+91 33 40705047</a></li>
                                            <li><strong>Mail Us -</strong><a href="mailto:info@thestaying.com">info@thestaying.com</a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Container>
                </div>
            </div>


            <div className="footerBottom">
                <div className="container">
                    <div className="footerBtext">
                        <p>Copyright © {currentYear} The Staying . All Rights Reserved.</p>
                        <p><span className="content-label">Design & developed by - <a href="https://mettletech.co/" target="_blank">Mettle Tech</a></span></p>
                    </div>
                </div>
            </div>
        </>
    );
}

export default (Footer);